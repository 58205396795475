//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../../fonts/kabelctt-medium.woff') format('woff');
//}
.stakeActionContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 4;

  width: 100%;
  background: url("../../../../images/stake/stakeActionBackground.svg") no-repeat  100% ;
  background-size: cover;
  min-height: 386px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  @media screen and (max-width:1300px ){
    background: #000000;
    border-radius: 40px;

  }
  @media screen and (max-width:1024px ){
    height: max-content;

  }
  @media screen and (max-width:800px ){

    padding-top: 20px;
    background: #000000;
    border-radius: 40px;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width:500px ){
    &__imageBackground{
      display: none;
    }
    padding-top: 60px;
    background: #000000;
    border-radius: 40px;
    max-width: 500px;
    height: 100%;
    width: 100%;
  }
}

.stakeActionContent{
  display: flex;
  padding: 40px;
  padding-right: 80px;
  padding-top: 40px;
  flex-direction: column;
  gap: 40px;
  &__backButton{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    gap: 20px;
    align-items: center;
    text-transform: capitalize;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    cursor: pointer;
  }
  &__contentRow{
    display: flex;
    flex-direction: row;
    gap: 30px;
    &__column1{
      display: flex;
      flex-direction: column;
      gap: 30px;
      min-width: 400px;
      width: 100%;
      padding: 0!important;
      border-radius:0 !important;
      &__buttonRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
      &__stakeBalanceRow{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 10px;
        &__labelRow{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &__title{
          font-family: 'Hind Vadodara';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          text-transform: capitalize;
          color: #FFFFFF;
        }
        &__balance{
          font-family: 'Hind Vadodara' !important;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 98.1%;

          font-feature-settings: 'pnum' on, 'lnum' on;
          background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          white-space: normal !important;
          gap: 5px;
          span{
            font-size: 24px;
          }
          p{
            font-size: 40px !important;
            font-family: 'Hind Vadodara' !important;
          }
        }
        &__button{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;

        }
      }
    }
    &__column2 {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 300px;
      position: relative;
      top:-80px;
      text-align: left;
      &__title{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 700;
        text-align: left;
        font-size: 24px;
        line-height: 28px;
        text-transform: capitalize;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
      }
      &__text{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
        opacity: 0.8;
      }
      span,strong{
        color: #FEC066;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    padding:30px;
  }
  @media screen and (max-width:1024px ){
    padding:30px;

    &__contentRow {
      flex-direction: column;
      &__column2{
        top:0;
        text-align: center;
        max-width: 100%;
        &__title{
          text-align: center;
        }
      }
    }

  }
  @media screen and (max-width: 800px){
    &__contentRow{
      flex-direction: column;
      &__column1{
        flex-direction: column;
        width: 100%;
        &__buttonRow{
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
        &__stakeBalanceRow{
          flex-direction: column;
          width: 100%;
          justify-content: center;

          &__labelRow{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 30px;
          }
          &__button{
            align-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;


          }
        }
      }
      &__column2 {
        display: none;
      }
    }
  }
  @media screen and (max-width:500px ){
    padding: 0 33px 33px;
    gap: 25px;
    &__mobileText {
      display: block;
    }
    &__stakeActionLabelRow{

      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    &__contentRow{
      flex-direction: column;
      &__column1{
        flex-direction: column;
        width: 100%;
        min-width: 100%;
        &__buttonRow{
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }
        &__stakeBalanceRow{
          flex-direction: column;
          width: 100%;
          justify-content: center;

          &__labelRow{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 30px;
          }
          &__button{
            align-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;


          }
        }
      }
      &__column2 {
        display: none;
      }
    }

  }
}
.stakeActionTitle{
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  opacity: 0.8;
  max-width: 311px;
}
