.TableColumn {
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 93.6%;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
  display: flex;
  max-width: 200px;
  min-width: 200px;
  align-items: center;
  justify-content: center;

  &__children{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__firstChild{
    justify-content: flex-start;
  }
  &__lastChild{
    justify-content: flex-end;
  }
  &.sort{
    p,img{
      cursor: pointer;
    }
  }

}




