.CollateralContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &__collateral {
    background: #000000;
    border-radius: 40px;
    max-width: 675px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 56px 64px;

    &__titleBlock {
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      max-width: 313px;

      &__title {
        font-weight: 700;
        font-size: 34px;
        line-height: 103.1%;
        text-align: center;
        text-transform: uppercase;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 58px;
      width: 100%;

      &__infoBlock {
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 40px;
        &__infoRow,p,.title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          font-family: 'Hind Vadodara';
          font-style: normal;
          font-weight: 500;
          font-size: 16px !important;
          line-height: 130%;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #FFFFFF;

        }
      }
    }

    &__backButton {
      align-self: flex-end;
    }

    @media screen and (max-width: 500px) {
      padding: 56px 44px;
      &__titleBlock {
        max-width: 100%;
      }
      &__backButton{
        align-self: center;
      }
    }
  }
}
