.infoItemContainer{
  position: relative;
  display: flex;
  //align-items: center;
  flex-direction: column;
  max-width: 309px;
  min-width: 250px;
  width: 100%;
  height: 256px;
  transform: skew(-5deg);
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  border-radius: 8px;

  &__infoItem{
    transform: skew(5deg);
    width: 100%;
  }
  @media screen and (max-width: 300px){
    height: 300px;
    min-width: 100%;
  }
}
.infoItemContainer::before{
  transition: all 0.2s linear;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  border: 1px solid transparent;
  background: linear-gradient(104.74deg,rgba(255, 255, 255, 0.15)
  ,#fff) border-box;
  -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

