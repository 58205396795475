.RepayInfo{
  background:url("../../../../images/withdraw/withdrawInfoBackground.svg") no-repeat 0% ;
  background-size: cover;
  position: relative;

  max-width: 600px;
  width: 100%;
  min-height: 400px;
  z-index: 5;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  left: -20px;
  .Value__value__text {
    font-size: 24px !important;
    align-items: center;
  }
  .SubValue__white {
    font-size: 12px !important;
  }
  &__imageBackground{
    position: absolute;
    z-index: -1;


  }
  &__content{
    padding: 60px 50px 0px 80px;
    display: flex;
    flex-direction: column;
    gap: 24px;

  }
  &__row{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 36px;
  }
  &__value{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
margin-top: 20px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    .titlep,p,span{
      font-size: 24px !important;
      line-height: 100%!important;
    }
    .HealthFactor__percent{

      margin:auto;
    }
  }
  &__usd{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    margin-top: 9px;
    opacity: 0.8;
  }
  &__balanceRow{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    &__title{
      margin-top: 30px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      color: #FFFFFF;
    }
  }
  @media screen and (max-width: 1160px) {
    background:#000000;
    border-radius: 40px;
    max-width: 100%;
    left: 0;

    &__content{
      padding:48px 72px;
      align-items: center;
    }
    &__row{
      justify-content: space-around;

    }
    &__imageBackground{
      display: none;
    }
  }
  @media screen and (max-width: 1250px) {
    &__content{
      padding: 60px 40px 0px 50px;
    }
  }
  @media screen and (max-width: 1220px) {
    &__content{
      padding: 60px 20px 0px 50px;
    }
  }
  @media screen and (max-width: 1160px) {
    &__content{
      padding: 60px 20px ;
    }
  }
  @media screen and (max-width: 600px) {
    &__row{
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 380px) {
    &__content{
      padding:48px 22px
    }
  }
}
