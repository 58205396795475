.SearchInput{
  background: #131313;
  border-radius: 50px;
  padding: 16px 24px;
  max-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  input{
    width: 100%;
    height: 100%;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    color: #FFFFFF;
    text-transform: uppercase;
  }
  input::placeholder{
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    color: #FFFFFF;
    text-transform: capitalize;
  }
}
