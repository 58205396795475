.BorrowInfoContainer{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  //padding: 40px 30px 10px;
  padding: 20px 30px 10px;
  gap: 15px;
  flex-direction: column;
  &__title{
    font-family: 'Hind Vadodara' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 40px;
    line-height: 98.1%;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    .Value {
      .Value__value {
        font-size: 40px !important; 
      }
    }  
    p{
      font-size: 40px !important;
      font-family: 'Hind Vadodara', sans-serif !important;
      font-weight: 700 !important;
    }
  }
  &__text{
    font-family: 'Hind Vadodara', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 93.6%;
    display: flex;
    align-items: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    gap: 5px;
  }
  @media screen and(max-width: 400px){
    &__title{
      p{
        font-size: 32px !important;
      }
    }
  }
}
//.BorrowInfoContainer > * + * {
//  margin-top: 15px;
//}
