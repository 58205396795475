//@font-face {
//    font-family: 'Hind Vadodara';
//    src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//         url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.CardButton {
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    width: max-content;
    min-height: 55px;
    //-webkit-filter: blur(2px);
    &__reflection{
        -webkit-box-reflect: below 2px linear-gradient(transparent, rgba(0, 0, 0, 0.13));
    }

    .Button{
        border:none !important;
        box-shadow: none !important;
        width: 100% !important;
        height: 100% !important;
        color: white !important;
        position: absolute;
        font-family: 'Hind Vadodara' ;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        text-transform: uppercase;
        padding: 0 10px 0 10px;
    }
    &__title {
        color: white;
        position: relative;
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        padding: 0 10px 0 10px;
        -webkit-text-fill-color: white !important;

        //top: -35px;
        //left: 80px;

    }

    &__wrapper {
        position: absolute;
        //width: 100%;
    }
    &__wrapperMobile {
        position: absolute;

    }
    &__wrapperMobile:active{
        opacity: 0.5;
    }
    &__background {
        position: absolute;
        //width: 95%;
        //top: 5px;
        //left: 5px;
    }
    @media screen and (max-width: 330px){
        &__wrapperMobile{
            min-width: 250px;
            width: 100%;
        }
    }
    &::before {
        pointer-events: none;
        content: "";
        position: absolute;
        background: transparent;
        top: 50%;
        left: 0;
        width: 100%;
        height: 100%;
        transform: perspective(3em) rotateX(0deg) scale(1, 0.5);
        filter: blur(15px);
        transition: ease-in-out all 0.2s;
    }

}
stop{
    transition: all 0.2s linear;
}
.Green,.Orange,.Pink,.Red{
    transition: all 0.2s linear;

}

.Green::before{
    background: rgba(21, 159, 95, 0.6) ;
}
.Red::before{
    background: rgba(21, 159, 95, 0.6) ;
}
.Orange::before{
    background: rgba(252, 171, 108, 0.6);
}
.Pink::before{
    background: rgba(247, 134, 212, 0.6);
}



