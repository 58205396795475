//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.trapezoidMobileButtonContainer{
  position: relative;
  display: flex;
  //padding: 15px 55px 15px 55px;
  //padding-bottom: 18px;
  align-items: center;
  z-index: 5;
  justify-content: center;
  cursor: pointer;
  max-width: 305px;
  width: 100%;

  &__absoluteBlock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    width: 100%;
    svg {
      width: 100%;
      height: 50px;
      //max-height: 50px;
      //height: 100%;
    }
  }
  &__buttonImg{
    object-fit: contain;
    width: 100%;
  }
  &__buttonSubmit{
    width: 100%;
    position: absolute;
    box-shadow: none !important;
  }
  &__backgroundLabel{
    position: absolute;
    top: 4px;
    right: -4px;
    width: 100%;
    object-fit:contain;
    z-index: -1;


  }
  stop{
    transition: all 0.2s linear;
  }

  p {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 93.6%;
    text-align: center;
    text-transform: uppercase;
    align-self: center;
    color: #FFFFFF;
    margin:15px
  }
  @media screen and (max-width: 340px) {
    &__backgroundLabel{
      top: 6px;
    }
  }
}
.actionButtonContainer:hover path{
  box-shadow: 0px 4px 4px rgba(254, 166, 65, 0.4);
}
.actionButtonContainer:hover stop:first-child {
  stop-color: #FEA23C;
}
.actionButtonContainer:hover stop:last-child {
  stop-color: #FED583;
}
