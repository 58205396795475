//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('/fonts/kabelctt-medium.ttf') format('truetype'),
//  url('/fonts/kabelctt-medium.woff') format('woff');
//}



.ManageMldContainer {


  .helpBlock_imageBackground {
    position: absolute;
    z-index: -1;
    top: 10px;
    right: 10px;
  }

  &__helpBlock {

    background: #000000;
    border-radius: 40px;
    padding: 40px;
    margin-top: 32px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    z-index: 10;

    &__buttonRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      p {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }
    }

    p {
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }

  @media screen and (max-width: 500px) {
    max-width: 500px;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;
    .helpBlock_imageBackground {
      display: none;
    }
    &__helpBlock {
      padding:50px 30px;
      text-align: center;
      width: 100%;
      &__buttonRow {
        flex-direction: column;
        width: 100%;
        p {
          width: 100%;
          align-items: center;
          justify-content: space-between;

          img{
            align-self: flex-end;
          }
        }
      }
    }
    }
  }
.containerRow{
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  @media screen and (max-width:1235px ){
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
  @media screen and (max-width:500px ){
    flex-direction: column;
    gap: 40px;
    align-items: center;
  }
}
.lockVest{
  position: relative;
  z-index: 4;
  max-width: 1030px;
  width: 100%;
  height: 448px;
  border-radius: 40px;
  &__imageBackground{
    position: absolute;
    z-index: -1;

    @media screen and (max-width:500px ){
      display: none;
    }
  }
  &__headerRow{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 35px;
    margin-bottom: 40px;
    &__headerLabel{
      padding-right: 68px;
      padding-left: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &__lineShining{
        max-width: 190px;
        width: 100%;
        position: absolute;
        left:-15px;
        top:-40px;
        @media screen and (max-width:500px ){
          left: -40px;
          height: 205px;
        }
      }
      &__redBookMark{
        max-width: 87px;
        width: 100%;
      }
      &__manageChest{
        max-width: 115px;
        width: 100%;
        position: absolute;
        right: 20px;
        bottom: -5px;

      }
    }
    &__title{
      font-family: 'Hind Vadodara', arial;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 124%;
      /* or 27px */

      display: flex;
      align-items: center;

      letter-spacing: -0.02em;
      font-feature-settings: 'pnum' on, 'lnum' on;

      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    @media screen and (max-width:500px ){
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      &__headerLabel{
        padding-left: 10px;
      }
    }
  }
  @media screen and (max-width:1405px ){
     margin-right: 380px;
    &__imageBackground{
      object-fit: contain;
      width: auto;
      height: 448px;
      display: none;
    }
    background-color: black;

  }
  @media screen and (max-width:1235px ){
    margin-right: 0;
  }
  @media screen and (max-width:1050px ){
    &__imageBackground{
      display: none;
    }
    background-color: black;
    height: 100%;

  }
  @media screen and (max-width:500px ){
    height: 100%;
    background-color: black;

    max-width: 100%;
    padding-bottom: 60px;
  }

}


.lockVestContainer{
  padding: 25px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-top:15px;
  height: 250px;

  &__itemContent{
    //margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    padding:30px;
    padding-bottom: 45px;
    justify-content: space-between;
    flex-direction: column;


    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 34px !important;
      line-height: 98.1%;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 15px;
      letter-spacing: -0.02em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      span{
        font-size: 34px !important;
      }
    }
    &__rowBetween{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top:17px
    }
    &__itemText{
      color: white;
      font-size: 16px;
      font-family: 'Hind Vadodara';
      display: flex;
      flex-direction: row;
    }

    span{
      color: white;
      font-family: 'Hind Vadodara';
      font-weight: 700;
    }

  }
  @media screen and (max-width:1050px ){
    flex-wrap: wrap;
    height: 100%;
    gap: 40px;
    padding: 25px;
  }
  @media screen and (max-width:500px ){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 30px;
    padding: 25px;
  }
  @media screen and (max-width:320px ){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 30px;
    padding: 15px;
  }
}
.lockMldContainer{
  position: relative;
  width: 100%;
  margin: 80px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width:1170px ){
    flex-direction: column;
    align-items: center;
    gap: 10px;

  }
  //@media screen and (max-width:500px ){
  //  flex-direction: column;
  //  gap: 10px;
  //}
}
.lockMldImageBackground{
  position: absolute;
  bottom: -25px;
  left: 30px;
  width: 90%;
  @media screen and (max-width:500px ){
    display: none;
  }
}
