.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  display: flex;
  overflow: auto;
  //background-color: rgba(0, 0, 0, 0.8);
  transition: 0.1s;
  backdrop-filter: blur(10px)

}


.ReserveStatusModalContainer {
  margin: auto;
  transform: scale(0.9);
  transition: 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  max-width: 1440px;
  width: 100%;
  z-index: 1;
  &__close {
    position: absolute;
    top: 52px;
    right: 32px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    z-index: 3;
  }
  &__reserveColumn {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }

  &__reserveStatus {
    background: url("../../images/reserveStatusModal/reserveStatusBackground.svg") no-repeat 100%;
    max-width: 1058px;
    width: 100%;
    padding: 0 35px 40px;
    display: flex;
    flex-direction: column;
    gap: 65px;
    height: 344px;

    &__titleBlock {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      &__manageChest {
        position: absolute;
        right: 20px;
        bottom: -7px;

      }

      &__title {
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 103.1%;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }

    &__infoBlock {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 14px;
    }

    &__infoItem {
      background: #131313;
      border-radius: 8px;
      padding: 42px 32px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      max-width: 280px;
      width: 100%;
      height: 120px;

      &__row {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        p,span{
          font-size: 16px !important;
        }
      }

      &__label {

        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 10px;
        padding: 12px 48px;
        top: -20px;
        //max-width: 250px;
        //width: 100%;
        svg {
          position: absolute;
          width: 100%;
          z-index: 1;
        }

        p {
          font-family: 'Hind Vadodara';
          font-weight: 500;
          font-size: 20px;
          line-height: 100%;
          letter-spacing: -0.02em;
          color: #FFFFFF;
          z-index: 2;
          margin-bottom: 6px;
          white-space: nowrap;
        }
      }
    }
    &__infoItemWithoutLabel{
      background:none;
    }
  }

  &__bottomBlock {
    background: url("../../images/reserveStatusModal/reserveStatusBottomBackground.svg") no-repeat 100%;
    max-width: 1006px;
    width: 100%;
    height: 200px;
    padding: 40px 98px;
    padding-right: 170px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;

      p {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;
      }

      &__value {
        font-size: 18px;
        line-height: 103.1%;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }

  &__statsBlock {
    background: url("../../images/reserveStatusModal/reserveStatusStatsBackground.svg") no-repeat 100%;
    max-width: 1144px;
    width: 100%;
    position: absolute;
    right: 0;
    bottom: -27px;
    padding: 32px;
    padding-right: 70px;
    height: 552px;
    z-index: -1;
    display: flex;
    flex-direction: column;


    &__content {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      max-width: 320px;
      align-items: center;
      width: 100%;
      gap: 43px;
      position: relative;
      z-index: 5;

      &__circular {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 700 !important;
        font-size: 18px !important;
        line-height: 21px;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        gap: 10px;
        color: #FFFFFF;

        img {
          width: 35px;
          height: 35px;
        }
      }



      &__contentItem {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__label {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
          font-family: 'Hind Vadodara';
          font-weight: 500;
          font-size: 18px;
          text-transform: uppercase;
          color: #FFFFFF;

          &__box {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            background: linear-gradient(132.34deg, #C0A1FB -0.81%, #DBC9FD 57.63%, #C0A1FB 118.9%)
          }

          &__boxActive {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
          }
        }

        &__value {
          font-family: 'Hind Vadodara';
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          display: flex;
          flex-direction: column;
          font-feature-settings: 'pnum' on, 'lnum' on;
          color: #FFFFFF;
          span {
            font-weight: 700;
            font-size: 32px;
            text-transform: uppercase;
            background: linear-gradient(134.4deg, #F36D0F -30.08%, #F8D48F 29.24%, #FFDFAD 87.35%, #FA9728 127.09%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-shadow: 0px 4px 10px rgba(183, 0, 65, 0.5);
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    &__reserveStatus{
      background-size: contain;
      max-width: 1020px;
    }
    &__bottomBlock{
      background-size: contain;
      max-width: 970px;
    }
  }
  @media screen and (max-width: 1360px) {
    &__reserveColumn{
      margin-right: 300px;
    }
    &__reserveStatus{
      background: url("../../images/reserveStatusModal/reserveStatusBackground.svg") no-repeat  ;
      background-size: contain;
    }
    &__bottomBlock{
      background: url("../../images/reserveStatusModal/reserveStatusBottomBackground.svg") no-repeat ;
      background-size: contain;

    }
    &__statsBlock{
      padding-right: 40px;
    }
  }
  @media screen and (max-width: 1300px) {
    &__reserveStatus,&__bottomBlock{
      height: max-content;
      background: #000000;
      border-radius: 40px;
      max-width: 100%;
    }
    &__statsBlock{
      padding-right: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    //margin-top: 30%;
    //@media screen and (max-height: 770px){
    //  top:10%;
    //
    //}

    flex-direction: column-reverse;
    background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
    border-radius: 40px;
    padding:8px;
    &__reserveColumn{
      margin-right: 0;
    }
    &__close{
      top:32px
    }

    &__statsBlock {
      position: initial;
      padding:0;
      height: max-content;
      background:none;
      &__content{
        align-self: flex-start;
        flex-direction: row;
        max-width: 100%;

      }
    }
  }
//  @media screen and (max-width: 920px) and (min-height: 1300px){
//top:0;
//    margin-top: 20px;
//  }
  @media screen and (max-width: 800px) {
    //position: fixed !important;
    //.ReactModal__Content{
    //  top:0 !important;
    //}
    //top:0;
    //margin-top: 40%;
    &__close{
      top:20px
    }
    &__reserveStatus {
      &__infoBlock{
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    &__bottomBlock{
      align-items: center;
      padding:47px
    }
    &__statsBlock {

      &__content{


      }
    }
    //@media screen and (max-height: 600px) {
    //  top:35%
    //}
  }
  @media screen and (max-width: 730px) {
    //margin-top: calc(100% - 50px);
    padding: 0 0 15px;

    &__close{
      top:30px
    }
    &__reserveStatus {
      padding:0;

      padding-bottom: 60px;
      &__infoBlock{
         gap: 40px;
      }
      &__infoItemWithoutLabel{
        padding-top: 0;
        padding-bottom: 0;
        height: max-content;
      }
    }
    &__statsBlock {
      padding-top: 40px;
      padding-bottom: 60px;
      &__content{
        flex-direction: column;
        gap: 20px;

      }
    }
  }
  //@media screen and (max-width: 670px) {
  //  top: 20%;
  //}
  @media screen and (max-width: 500px) {
    //top:50%;
    //margin-top: 150%;
    &__reserveStatus {
      padding: 0 32px 32px;

      &__titleBlock{
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;

      }
    }
  }

  @media screen and (max-width: 400px) {
    //margin-top: 250%;
    &__reserveStatus {
      padding: 0 24px 24px;
      &__titleBlock__title{
        font-size: 30px;
      }
    }
    }
  //@media screen and (max-width: 375px) and (max-height: 700px){
  //  margin-top: 300%;
  //}
  //@media screen and (max-width: 360px) {
  //  margin-top: 300%;
  //}
  //@media screen and (max-width: 300px) {
  //  margin-top: 500%;
  //}
}
