.CoinInputContainer{
  background: #131313;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 9px 25px 9px 15px;
  gap: 10px;
  height: 64px;
  &__error{
    border: 1px solid #de5959;
  }
  input{
    color: white;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 93.6%;
    width: 100%;
  }
  input::placeholder{
    color: white;
  }
  &__tokenImg{
    width: 35px;
    height: 40px;
  }
  p{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;


  }
  &__max{
    background:transparent;
    text-decoration: underline;
    text-decoration-color: transparent;
  }
  &__max:hover{
    background: linear-gradient(180deg, #FDDCA4 0%, #FCB158 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-decoration-color: #FCB158;

  }
  &__error-text{
    color:#de5959;
    font-family: 'Hind Vadodara';
    font-size: 14px;
  }
}
