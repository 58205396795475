.TableContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  max-width: 1440px;
  max-height: 1088px;
  //overflow-y: auto;
  //overflow-x: hidden;
  &__content{
    background: #131313;
    border-radius: 12px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;

  }
  table{
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 8px;

  }
  @media screen and (max-width: 1300px) {
    overflow: auto;
  }
  @media screen and (max-width: 1024px){
    align-items: flex-start;
    &__content{
      min-width: 1262px;
      width: 100%;
    }
  }
  //@media screen and (max-width: 800px){
  //  //overflow: hidden;
  //
  //}
  @media screen and (max-width: 600px){
    min-width: 100%;
    width: 100%;
    max-height: 100%;
    &__content{
      min-width: 100%;
      border-radius: 35px;
      background:transparent;
      padding:0;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
.TableContainer::-webkit-scrollbar {
  width: 3px;

}

/* Track */
.TableContainer::-webkit-scrollbar-track {

  border-radius: 10px;
  background:#414040;
}

/* Handle */
.TableContainer::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D9D9D9;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}
