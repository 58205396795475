.ManageVestingContainer{
  position: relative;
  background: url("../../../../images/manageMld/vesting/vestingContainer.svg") no-repeat 100%  ;
  background-size: cover;
  padding: 60px 50px 40px;
  padding-right: 88px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top:45px;
  border-radius: 40px;
  &__labelRow{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 93.6%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
  &__balanceBlock{
    display: flex;
    flex-direction: row;
    gap:45px;
    width: 100%;
    &__balanceRow{
      display: flex;
      flex-direction: column;
      gap: 13px;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 100%;
      text-align: center;
      letter-spacing: -0.02em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      &__balance{
        font-family: 'Hind Vadodara';
        img{
          width: 37px;
          height: 37px;
          margin-right: 12px;
        }
        display: flex;
        align-items: center;
        font-weight: 700;
        //gap: 12px;
        font-size: 40px;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        line-height: 98.1%;

      }
      &__balanceUsd{
        color: #FFFFFF;
        display: flex;
        gap: 5px;
      }
    }
    &__buttonRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: 15px;
      width: 65%;
      &__penalty{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: white;
      }
      &__button{
        //width: 100%;
        display: flex;
        justify-content: center;
        .title{
          font-size: 14px !important;
        }

        p{

          display: flex;
          align-items: center;
          gap: 5px;
        }
        &__desktop{
          display: flex;
        }
        &__mobile{
          width: 100%;
          display: none;
        }
        @media screen and (max-width: 900px) {
          &__desktop{
            display: none !important;
          }
          &__mobile{
            width: 100%;
            display: flex;
          }
        }
      }
    }
  }
  &__actionBlock{
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 20px;
    align-items: flex-end;
    &__buttonsRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }
    span{
      align-self: flex-start;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      color: #FFFFFF;
      opacity: 0.8;
    }
  }
  @media screen and (max-width: 1440px){
    background: url("../../../../images/manageMld/vesting/vestingContainer.svg") no-repeat   ;
  }
  @media screen and (max-width: 1024px){
    padding-right: 20px;
  }
  @media screen and (max-width:900px ){
    background: url("../../../../images/manageMld/vesting/vestingMobileContainer.svg") no-repeat 100%;
    background-size: cover;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding:20px;
    padding-top: 70px;
    padding-bottom:50px ;
    &__balanceBlock{
      gap: 38px;
      width: 100%;
      &__buttonRow{
        flex-direction: column;
        width: 100%;
        &__button{
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    &__actionBlock {
      padding-bottom: 17px;
      &__buttonsRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
      }

    }
  }
  @media screen and (max-width: 500px) {
    &__actionBlock {

      &__buttonsRow {
        padding-left: 15px;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
      }

    }
  }
  @media screen and (max-width:400px ){
    background: url("../../../../images/manageMld/vesting/vestingMobileContainer.svg") no-repeat center;
    background-size: cover;
    &__balanceBlock{
      gap: 38px;
      width: 100%;
      &__balanceRow{

        flex-direction: column;
        width: 100%;
        align-items: center;

      }
    }
    &__actionBlock {

      &__buttonsRow {
        padding-left: 0;

      }

    }
  }
}
