.HealthFactorSlider {
  margin: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  &__top-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2px;
    min-height: 16px;
  }

  &__title {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    text-transform: capitalize;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    opacity: 0.9;
  }
  &__title:first-child{
    color:#3cecd1
  }
  &__title:last-child{
    color:#ff59a4
  }
  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 5px;
    p{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.9;
    }
  }

  &__range-inner {
    margin-top: 7px;
    width: 100%;
    border-radius: 10px;

  }

  .HealthFactorSlider__track {
    height: 8px;
    width: calc(100% - 14px);
    margin: 0 auto;
    border-radius: 10px;
    background:linear-gradient(90deg, #7DCD9B 0%, #E9B44D 22.71%, #EB8C6B 52.92%, #EB5D8D 100%) ;

  }

  .HealthFactorSlider__thumb {
    width: 20px;
    height: 20px;
    border-radius: 28px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    outline: none !important;
    background: #C3BD69;
  }
}
