.TableItem{
  width: 100%;
  padding:36px 56px;
  background: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: ease-in-out all 0.2s;
  cursor: pointer;
  //overflow-x: auto;
  //justify-content: space-between;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  @media screen and (max-width: 600px){
    display: none
  }
  &__deposits{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    text-transform: uppercase;
    color: #FFFFFF !important;
    img{
      width: 47px;
      height: 47px;
      margin:0 !important;
    }
  }
  &__borrow{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    // TODO: Values2
    // .Value__value{
    //   margin-bottom:10px !important;
    // }
    &__value{

      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 18px !important;
      text-transform: capitalize;
      color: #FFFFFF;
      p{
        font-size: 18px !important;
      }

    }
  }
  &__apy{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 98.1%;
    text-align: center;
    color: #FFFFFF;
    gap: 7px;
    &__percent{
      background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 20px;
    }
    &__apr{
      border: 1px solid #313131;
      backdrop-filter: blur(2px);
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding:14px 16px;
      gap: 6px;
      &__circle{
        background: #FFD65A;
        width: 10px;
        height: 10px;
        border-radius: 50%;

      }
    }

  }
  &__collateral{
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 98.1%;
    text-align: center;
    color: #FFFFFF;
    &__status{
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      border-radius: 15px;
      width: 47px;
      height: 25px;
      padding:3px;
      display: flex;
      flex-direction: row;
      position: relative;
      &__statusCircle{
        display: flex;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        position: absolute;
      }
      &__trueStatus{
        background: linear-gradient(217.18deg, #13B366 5.68%, #15F087 74.05%);
        right: 3px;
      }
      &__falseStatus{
        background: linear-gradient(103.34deg, #E3022C 13.55%, #F2106A 83.74%);
        left: 3px;
      }
    }
  }
  &__actionButtons{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
  }
  @media screen and (max-width: 800px) {
    width: 1262px;
  }
}
.TableItem:hover{
  background: #000000;
}
