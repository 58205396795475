.LabeledSwitcher{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  position: relative;
  z-index: 5;

  &__button{
    max-width: 135px;
    width: 100%;
  }

}
