//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../../fonts/kabelctt-medium.woff') format('woff');
//}

.LendingDashboardBlock{
  position: relative;
  z-index: 4;
  width: 100%;
  border-radius: 40px;
  background:url("../../../../images/stake/stakePool2Background.svg") no-repeat 100%;
  background-size: cover;
  padding-bottom: 20px;
  &__imageBackground {
    position: absolute;
    z-index: -1;
    width: 100%;
  }
  //  @media screen and (max-width:500px ){
  //    display: none;
  //  }
  //}

  &__headerRow{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 35px;
    margin-bottom: 30px;
    &__manageChest{
      position: absolute;
      right: 40px;
      bottom: -5px;

    }
    //&__headerLabel{
    //  padding-right: 68px;
    //  padding-left: 35px;
    //  display: flex;
    //  align-items: center;
    //  justify-content: center;
    //  position: relative;
    //  &__lineShining{
    //    max-width: 190px;
    //    width: 100%;
    //    position: absolute;
    //    left:0px;
    //    top:-30px;
    //    @media screen and (max-width:500px ){
    //      left: -40px;
    //      height: 205px;
    //    }
    //  }
    //  &__redBookMark{
    //    max-width: 87px;
    //    width: 100%;
    //  }
    //  &__manageChest{
    //    position: absolute;
    //    right: 40px;
    //    bottom: -5px;
    //
    //  }
    //}
    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      position: relative;
      margin-bottom: 5px;
      img{
        position: absolute;
        left:-20px;
        top:-10px
      }
    }
    &__availableDeposit{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background:url("../../../../images/dashboard/availableBlock.svg") no-repeat 100%;
      background-size: contain;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      letter-spacing: -0.04em;
      text-transform: uppercase;
      color: #FFFFFF;
      padding:13px 23px;
      margin-left: 20px;
    }
    @media screen and (max-width:500px ){
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      &__manageChest{
        right: 10px;
      }
      &__headerLabel{
        padding-left: 10px;
      }
    }
  }
  &__stakeContent{
    padding: 25px;
    padding-right: 35px;
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    align-items: center;
    justify-content: center;
    -webkit-justify-content:center;
    position: relative;
    width: 100%;
    gap: 20px;
    //> * + * { margin-left: 20px;}


    &__itemContent{
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      padding:30px;
      padding-bottom: 45px;
      justify-content: space-between;
      flex-direction: column;

      &__title{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 98.1%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 15px;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      &__rowBetween{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top:17px
      }
      &__itemText{
        color: white;
        font-size: 16px;
        font-family: 'Hind Vadodara';
        font-weight: 500;
      }
      span{
        color: white;
        font-family: 'Hind Vadodara';
        font-size: 20px;
        font-weight: 500;

      }

    }
    @media screen and (max-width:1150px ){
      flex-wrap: wrap;
      //height: 100%;
      gap: 50px;
      padding: 25px;

    }
    @media screen and (max-width:500px ){
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 50px;
      padding: 27px;
      //> * + * { margin-left: 0}
    }
    @media screen and (max-width:320px ){

      width: 100%;
    }
  }

  @media screen and (max-width:500px ){
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    &__headerLabel{
      padding-left: 10px;
    }
  }

  @media screen and (max-width:900px ){
    &__imageBackground{
      display: none;
    }
    background-color: black;
    height: 100%;

  }


}
