.LoopInfoBlockBig{
  max-width: 100%;
  width: 90%;
}
.LoopInfoBlock{
  display: flex;
  flex-direction: column;
  background: url("../../../../images/loop/loopInfoBlock.svg") no-repeat  ;
  background-size: cover;
  padding:70px 80px 88px;
  min-height: 475px;
  max-width: 555px;
  width: 100%;
  gap: 24px;
  border-radius: 40px;
  //border-top-left-radius: 40px;
  //border-bottom-left-radius: 40px;
  height: 100%;
  &__infoRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      //line-height: 17px;
      display: flex;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
    }
    &__value{

      span,p {
        font-size: 16px !important;
        font-weight: 700 !important;
      }
    }
  }
  @media screen and (max-width: 1000px){

    padding-left: 50px;
  }
  @media screen and (max-width: 900px){
    background: linear-gradient(156.29deg, #E3022C -27.21%, #F2106A 44.22%);
    max-width: 100%;
    padding: 57px 32px;
  }
}
