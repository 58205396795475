//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.RoundedButtonContainer{
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  position: relative;
  border-radius: 50px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s linear;
  p{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    position: relative;
    white-space: nowrap;
    //z-index: -1;
  }
}
.RoundedButtonContainer::selection{
  background:none;
}
.RoundedButtonContainer:hover::before{
  transition: all 0.2s linear;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 1px solid transparent;
  background: linear-gradient(104.74deg,#FEA33C
  ,#EAC8A2) border-box;
  -webkit-mask:
          linear-gradient(#fff 0 0) padding-box,
          linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
.RoundedButtonContainer:active{
  background: linear-gradient(104.74deg,#FEA33C,#EAC8A2)
}
.RoundedButtonContainerActive{
  background: linear-gradient(104.74deg,#FEA33C,#EAC8A2)
}
