.BackButton{
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  font-feature-settings: 'pnum' on, 'lnum' on;
  gap: 15px;
  color: #FFFFFF;
  cursor: pointer;
  width: max-content;
  background:transparent;
  transition: ease-in-out all 0.2s;
  stop:first-child {
    stop-color: #Ffffff;
  }
  stop:last-child {
    stop-color: #ffffff;
  }

}
.BackButton:hover{
  background: linear-gradient(175.54deg, #F5D153 48.71%, #DD8039 90.98%, #FFD65A 152.26%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  stop:first-child {
    stop-color: #FEA23C;
  }
  stop:last-child {
    stop-color: #FED583;
  }
}
