.WithdrawInfo{
  background:url("../../../../images/withdraw/withdrawInfoBackground.svg") no-repeat 0% ;
  background-size: cover;
  position: relative;

  max-width: 600px;
  width: 100%;
  min-height: 395px;
  z-index: 5;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  left: -20px;
  &__imageBackground{
    position: absolute;
    z-index: -1;


  }
  &__content{
    padding: 56px 72px 0px 100px;
    display: flex;
    flex-direction: column;
    gap: 48px;

  }
  &__row{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 36px;
  }
  &__value{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    .title,p,span{
      font-size: 32px !important;
      line-height: 100% !important;
    }
  }
  &__usd{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    //gap: 10px;
    p{
      font-size: 20px !important;

    }
    span{
      margin-left: 10px;
    };
  }
  @media screen and (max-width: 1300px) {
    //background:#000000;
    //border-radius: 40px;
    //&__content{
    //  padding: 50px 73px 40px 50px;
    //}
    //
    //&__imageBackground{
    //  display: none;
    //}
  }
  @media screen and (max-width: 1200px) {
    max-width: 500px;
    //left: 0;
    &__content{
      padding:48px 72px;
      padding-right: 30px;
    }

  }
  @media screen and (max-width: 1160px) {
    background:#000000;
    border-radius: 40px;
    max-width: 100%;
    left: 0;
    &__content{
      padding:48px 72px
    }
    &__imageBackground{
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    &__row{
      flex-direction: column;
    }
  }
  @media screen and (max-width: 600px) {
    &__content{
      padding:48px 22px
    }
  }
}
