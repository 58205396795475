.WithdrawAction{
  position: relative;
  background:url("../../../../images/withdraw/withdrawActionBackground.svg") no-repeat 100%  ;
  background-size: cover;
  max-width: 800px;
  width: 100%;
  z-index: 5;
  min-height: 395px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  &__imageBackground{
    position: absolute;
    z-index: -1;
    top:2px;
    object-fit: contain;
  }
  &__content{
    padding: 50px 73px 30px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    height: 100%;
  }
  &__mainBlock{
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
    height: 100%;
  }
  &__overviewBlock{
    &__rows{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px;
    }

  }
  &__headerBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    &__title{
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      &__token{
        font-weight: 700;
        font-size: 34px;
        line-height: 103.1%;
        text-transform: uppercase;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        gap: 24px;
        img{
          width: 35px;
          height: 35px;
        }
      }
      span{
        align-self: flex-end;
      }
    }
  }
  &__buttonRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    &__availableToWithdraw{
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      align-items: center;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      &__value{
        font-weight: 700;
        font-size: 40px;
        line-height: 98.1%;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
    }
    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1160px) {
    background:#000000;
    border-radius: 40px;
    max-width: 100%;
    &__imageBackground{
       display: none;
    }
    &__content{
      padding: 0 35px 32px 35px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
    &__overviewBlock{
      padding-top: 30px;

    }
    &__headerBlock{
      align-items: center;
      justify-content: center;
      &__title {
        &__token {
          flex-direction: column;
          gap: 10px;
          img{
            position: relative;
            top:-10px;
            width: 51px;
            height: 51px;
          }
        }
      }
    }

  }
  @media screen and (max-width: 500px) {
    &__buttonRow{
      flex-direction: column;
      gap: 40px;
      &__button{
        width: 100%;

      }
    }
  }
}
