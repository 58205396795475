//@font-face {
//    font-family: 'Hind Vadodara';
//    src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//         url('../../fonts/kabelctt-medium.woff') format('woff');
//}

.HeaderCardWrapper {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 15px;
    height: 291px;
    width: 204px;
    padding: 8px;

    &__body {
        background: linear-gradient(360deg, #071440 -5.69%, #1D37EF 61.88%);
        border-radius: 8px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &__title {

            background: linear-gradient(90.06deg, #175ABE 6.54%, rgba(6, 110, 186, 0) 118.23%);
            filter: drop-shadow(0px 20px 20px rgba(9, 55, 105, 0.5));
            border-radius: 8px 8px 0px 0px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-top: 17px;
            padding-bottom: 17px;

            span {
                color: white;
                font-family: 'Hind Vadodara';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 98.1%;
            }
        }

        &__content {
            //background: url(../../images/header/star-bg.svg) 100%;
            height: 221px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 2;
            overflow:hidden;
            &__star{
                position: absolute;
                z-index: -1;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &__image {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        &__value {
            background: linear-gradient(222.48deg, #2038DE 39.42%, #041654 146.74%);
            border-radius: 0px 0px 8px 8px;
            height: 55px;
            width: 100%;
            padding-top: 15px;
            padding-bottom: 16px;
            display: flex;
            flex-direction: row;
            justify-content: center;

            div {
                font-family: 'Hind Vadodara';
                font-style: normal;
                font-weight: 500;
                font-size: 28px;
                line-height: 98.1%;
                /* or 27px */

                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;
                // width: 100%;
                //letter-spacing: -0.02em;
                font-feature-settings: 'pnum' on, 'lnum' on;

                background: linear-gradient(53.52deg, #FFB47C 15.36%, #FAB477 19%, #FFDFB0 41.06%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }
}
