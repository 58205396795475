.QuickBlockContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #131313;
  border-radius: 12px;
  gap: 40px;

  &__stepRow{
    background: #2F2F2F;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    &__button{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 40px;
      p{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
    &__buttonActive:first-child{
      background: linear-gradient(101.7deg, #FEA23C 40.88%, #FED583 81.83%);
    }
    &__buttonActive{
      background: linear-gradient(101.7deg, #FED583 40.88%, #FED583 81.83%);
    }
    &__button:first-child{
      border-bottom-left-radius: 50px;
      border-top-left-radius: 50px;

    }
    &__button:last-child{
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;

    }
  }
  &__stepContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:45px;
    padding-top: 0;
    padding-left: 30px;
    &__textBlock{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
    &__stepTitle{
      font-family: 'Hind Vadodara';
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #FFFFFF;
    }
    &__stepText{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      opacity: 0.8
    }
  }

  @media screen and (max-width: 800px) {
    &__stepContent {
      padding: 30px;
      padding-top: 0;
    }
  }
  @media screen and (max-width: 500px) {

    &__stepContent{
      flex-direction: column;
      gap: 30px;
      padding:24px
    }
    &__stepErrorContent{
      padding:32px;
      padding-top: 0;
      &__buttonRow{
        flex-direction: column;
      }
    }

  }
}
