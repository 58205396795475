.DailyRevenueContent{
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 30px 30px 45px;
  gap: 43px;
  flex-direction: column;

  &__title{
    font-family: 'Hind Vadodara' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 34px !important;
    line-height: 98.1%;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 15px;
    letter-spacing: -0.02em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    span{
      font-size: 34px !important;
    }
  }
  &__rowBetween{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top:17px
  }
  &__itemText{
    color: white;
    font-size: 16px;
    font-family: 'Hind Vadodara';
    font-weight: 500;
  }
  span{
    color: white;
    font-family: 'Hind Vadodara';
    font-size: 20px;
    font-weight: 500;
  }

}
