//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../../fonts/kabelctt-medium.woff') format('woff');
//}
.AboutPoolContainer{
  //background: black;
  position: relative;
  z-index: 5;
  border-radius: 40px;
  padding: 0 70px 55px;
  left:-25px;
  display: flex;
  flex-direction: column;
  gap: 13px;


  width: 40%;
  background: url("../../../../images/stake/aboutPoolBackground.svg") no-repeat ;
  background-size: cover;
  height: 386px;
  &__imageBackground{
    position: absolute;
    left: -25px;
    z-index: -1;
  }
  &__labelBlock{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top:-10px;
    img{
      position: absolute;
      z-index: -1;
    }
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      letter-spacing: -0.04em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
    }
  }
  &__textBlock{
    margin-top: 45px;
    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
      /* or 23px */
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      margin-bottom: 40px;

    }
    span{
      color:#FEA23C
    }
    ol{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
      display: flex;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.8;
      list-style-type: decimal;
      margin-bottom: 24px;
    }
    @media screen and (max-width:1024px ){
      text-align: center;
      ol{
        justify-content: center;
      }
    }
    @media screen and (max-width:600px ){
      text-align: left;
      ol{
        justify-content: flex-start;
      }
    }
  }

  &__button{
    a{
      width: 100%;
    }
    display: flex;
    align-self: flex-end;
    &__desktop{
      width: 100%;
      display: flex;
    }
    &__mobile{
      width: 100%;
      display: none;
    }
    @media screen and (max-width: 1130px) {
      &__desktop{
        display: none;
      }
      &__mobile{
        width: 100%;
        display: flex;
      }
    }
  }
  @media screen and (max-width:1300px ){
    background: #000000;
    border-radius: 40px;
    width: 50%;
    left: 0;
    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 100%;
      }
    }
  }
  @media screen and (max-width:1024px ){
    flex-direction: column;
    position: initial;
    left:0;
    padding: 50px 30px;
    padding-top: 0;
    width: 100%;
    background: black;
    height: max-content;
    &__button{
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 100%;
      }

      width: 100%;
    }
  }
}
