.DashboardContainer {
  display: flex;
  flex-direction: column;
  gap: 80px;
  &__dashboardInfoBlock{
    position: relative;
    width: 100%;

    &__imageBackground{
      position: absolute;
      right: -30px;
      bottom:-25px
    }
  }
  &__tableBlock{
    background: #000000;
    border-radius: 40px;
    padding:40px 56px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__table{
      overflow-x: auto;
      width: 100%;
      background: #000000;

    }
  }
  @media screen and (max-width: 1400px) {
    &__tableBlock{
      background: #000000;
      border-radius: 40px;
      padding:40px 20px;


    }
  }
  @media screen and (max-width: 1150px) {
    &__dashboardInfoBlock {
      &__imageBackground {
        display: none;
      }
    }
  }
  @media screen and (max-width: 900px) {
    padding: 140px 8px 72px 8px;
    gap: 16px;

    &__tableBlock{
      background:transparent;
      padding:0;
      &__table{
        border-radius: 40px;
        overflow-x: hidden;
        padding:8px;
        gap: 8px;
      }
    }
  }
}
