.BasicForm{
  //background: red;
  border-radius: 40px;
  //padding:56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  width: 100%;
  &__titleBlock{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    max-width: 360px;
    &__title{
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      text-align: center;
      text-transform: uppercase;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
  }
  &__inputBlock{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    &__labelRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      &__value{
        font-weight: 700;
        font-size: 24px;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: flex;
        flex-direction: row;
        gap: 5px;
        p{
          font-size: 24px !important;
        }
      }
    }
  }
  &__buttonRow{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &__button{
      &__desktop{
        display: flex;
      }
      &__mobile{
        width: 100%;
        display: none;
      }
      @media screen and (max-width: 800px) {
        &__desktop{
          display: none;
        }
        &__mobile{
          width: 100%;
          display: flex;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    max-width: 100%;
    &__buttonRow {
      flex-direction: column;
      align-items: center;
      gap: 32px;
      &__reverse{
        flex-direction: column-reverse;
      }
      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    //padding: 56px 32px 32px;
    &__inputBlock{
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      &__labelRow{
        p{
          max-width: 92px;
        }

      }
    }
    &__buttonRow {
      //flex-direction: column-reverse;
      gap: 32px;
      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
