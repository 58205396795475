.HeaderContainer{
  background: #131313;
  border-radius: 50px;
  width: 100%;

  flex-direction: row;
  padding:18px 56px;
  height: 51px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 1024px){
    min-width: 1262px;
  }

}
.HeaderContainerMobile{
  @media screen and (max-width: 600px){
    display: none;
  }
}
