.MobileHeaderWrapper {
  display: flex;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  opacity: 1;
  animation: fade-in 0.2s;
  flex-direction: column;
  //position: relative;
  //align-items: center;
  //justify-content: center;
  width: 100%;
  z-index: 90;
  transition: ease-in-out all 0.2s;
  background: #000000;
  //@media screen and(max-width: 800px) {
  //  display: none;
  //}
  &__header {
    background-color: #000000;
    //height: 91px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__img {
      align-self: flex-end;
      margin-bottom: -10px;
      height: 76px;
      width: 180px;

    }
  }

  //&__header.open {
  //  background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
  //}

  @media screen and (max-width: 500px) {
    &__header {

      &__img {
        display: none;
      }
    }
  }
  @media screen and (max-width: 320px) {
    max-width: 320px;
  }
}

.MobileHeaderLabel {
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  transition: ease-in-out all 0.5s;
  padding: 20px 8px 15px 8px;

  &__balance {
    display: flex;
    flex-direction: row;
    //width: 79px;
    height: 40px;
    align-items: center;
    gap: 15px;
    margin-right: 28px;

    &__logo {
      display: flex;
      flex-direction: row;
      margin-right: 15px;
    }

    &__value {
      color: #FFFFFF;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;
      font-size: 16px;
      line-height: 166.1%;
    }
  }

}

.MobileHeaderWrapper.open {

  opacity: 1;
  animation: fade-in 0.2s;

}

.MobileHeaderLabel.open {
  animation: gradientBackground 0.2s;
  background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
}

.MobileHeaderContent {
  //position: absolute;
  z-index: 1000;
  transition: ease-in-out all 0.5s;
  background: #000000;
  display: none;
  width: 100%;
  //top:165px;
  //height: 100%;
  flex-direction: column;
  opacity: 0;
  backdrop-filter: blur(10px);

  &__content {
    padding: 75px 41px;
    padding-bottom: 10px;
    display: flex;
    height: max-content;
    overflow-y: auto;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    width: 100%;

    flex-wrap: wrap;
    @media screen and (max-height: 650px) {
      padding-top: 50px;
    }
  }

  &__item {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 166.1%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #646464;
    transition: ease-in-out all 0.2s;

  }

  &__item.active {
    color: #FFFFFF;
  }

  //@media screen and (max-width: 600px){
  //  padding-top: 20%;
  //}

}

.MobileFooter.open {
  animation: fade-in 0.5s;
  opacity: 1;
}

.MobileHeaderContent.open {
  display: flex;
  //animation: fade-in 0.5s ;
  opacity: 1;

}

.MobileHeaderContent__content.dotsOpen {
  padding-bottom: 50px;
  //display: flex;
  //flex-direction: column;
  //flex-wrap: wrap;
  //@media screen and (max-height: 650px) {
  //  padding-top: 0;
  //  display: grid;
  //  grid-template-columns: 1fr 1fr;
  //  gap: 0;
  //  padding-bottom: 0;
  //}
  //@media screen and (max-height: 670px) {
  //  padding-top: 0;
  //  display: grid;
  //  grid-template-columns: 1.5fr 0.5fr;
  //  height: 300px;
  //  gap: 0;
  //  padding-bottom: 0;
  //}
  //@media screen and (max-height: 740px) {
  //
  //  padding: 5%;
  //}
}

.MobileFooter {
  background: #0C0C0C;
  width: 100%;
  //position: fixed;
  //bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  height: 95px !important;
  opacity: 0;
  z-index: 1000;
  padding: 35px 0 35px;
  padding-bottom:35px;

  img {
    width: 28px;
    height: 25px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gradientBackground {
  0% {
    background: #000000;
  }
  50% {
    background: linear-gradient(102.84deg, #E3022C 13.65%, #F2106A 84.36%);
  }
  100% {
    background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
  }
}
