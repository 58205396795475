.Footer {
    background-color: black;
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content{
        max-width: 1440px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        gap: 70px;
        padding-right: 152px;
        //padding-top: 64px;
        padding-left: 88px;
    }
    &__logo{

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 55px;
        #logo{
            width: 180px;
            height: 76px;
        }
        &__links{
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: 30px;
            &__item{
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
    }
    &__menu {
        display: flex;
        flex-direction: row;
        //align-items: flex-end;

        font-family: 'Hind Vadodara';
        font-style: normal;
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 18px;
        //width: 100%;
        gap: 40px;
        a {
            color: white;
            text-decoration: none;
            opacity: 0.8;
        }
        a:hover{
            opacity: 1;
            background: linear-gradient(188.35deg, #F5D153 49.84%, #DD8039 88.19%, #FFD65A 119.78%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
@media screen and (max-width: 800px) {
    height: max-content;
    &__content {
        padding: 46px 0 64px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
    &__logo {
        gap: 80px;

        &__links {
            &__item {
                width: 28px;
                height: 21px;
            }
        }

    }
    &__menu {
        width: max-content;
        padding-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        font-size: 18px;
        gap: 35px;
    }
}
    @media screen and (max-width: 320px) {
        &__menu {
            width: max-content;
            padding-bottom: 10px;
            display: flex;
            text-align: center;
            flex-direction: column;
            justify-content: center;
            font-size: 18px;
            gap: 35px;
        }
    }
}
