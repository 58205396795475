//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.LiquidationOverviewContainer{
  background: #000000;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 675px;
  width: 100%;
  padding: 40px;

  position: relative;
  &__close{
    position: absolute;
    width: 18px;
    height: 18px;
    cursor: pointer;
    right: 30px;
    top:30px
  }

  &__headerBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      /* or 35px */
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(129.91deg, #F36D0F -0.14%, #F8D48F 35.69%, #FFDFAD 70.78%, #FA9728 94.78%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    &__text{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.8;
      max-width: 245px;
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    &__itemRow{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      color: #FFFFFF;
    }

  }
  @media screen and (max-width: 370px ) {
    padding:20px;
    padding-top: 45px;
  }
}
