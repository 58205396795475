.InputBar {
  margin: 10px 0;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  &__top-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2px;
    min-height: 16px;
  }

  &__title {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    text-transform: capitalize;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    opacity: 0.9;
  }

  &__label {
    display: flex;
    flex-direction: row;
    width: 120px;
    div {
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.9;
    }

    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  &__range-inner {
    margin-top: 7px;
    width: 100%;
    border-radius: 10px;

  }

  .InputBar__track {
    height: 8px;
    width: calc(100% - 14px);
    margin: 0 auto;
    border-radius: 10px;

  }

  .InputBar__thumb {
    width: 20px;
    height: 20px;
    border-radius: 28px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    outline: none !important;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
  }
}
