.BorrowOverview{
  background: #000000;
  border-radius: 40px;
  padding:57px 64px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  &__overviewTitle{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    &__text{
      max-width: 360px;
    }
    &__title{

      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      text-transform: uppercase;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

  }
  &__overview{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    margin-bottom: 55px;
    &__overviewRow{
      font-size: 16px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .title{
        font-size: 16px !important;
      }
      &__amount{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-end;
        &__usd{
          font-size: 14px;
        }
      }
      span{
        font-weight: 700;
      }
    }
    &__overviewRow:first-child{
      align-items: flex-start;
    }
  }
  &__buttonBack{
    align-self: flex-end;


  }
  @media screen and (max-width: 800px) {
    padding: 56px 30px 30px;
    &__buttonBack{
      align-self: center;
    }
  }
}
