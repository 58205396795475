//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../../../fonts/kabelctt-medium.woff') format('woff');
//}
.LockExpiryContainer{
  //background: black;
  position: relative;
  z-index: 5;
  border-radius: 40px;
  padding:70px;
  padding-top: 55px;
  left:-25px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 50%;
  height:370px ;
  background: url("../../../../../images/manageMld/lockMld/lockExpiryContainer.svg") no-repeat  ;
  background-size: cover;
  &__noData{
    padding: 0 !important;
    .Caption__primary{
      margin-bottom: 0 !important;
    }

  }
  &__total{
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      line-height: 17px;
      color: #FFFFFF;
      font-size: 12px;
      span{
        font-weight: 700;
        font-size: 16px;
      }

    }
  }
  &__imageBackground{
    position: absolute;
    z-index: -1;
    left: 0;
    top:10px;
    object-fit: cover;

  }
  &__headerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    width: 100%;
    border-bottom: 2px solid #2e2e2d;
    padding-bottom: 5px;
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      text-transform: capitalize;
      color: #FFFFFF;
      width: 100%;
    }

  }
  &__table{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;

    &__tableItem{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #2e2e2d;
      width: 100%;
      padding-bottom: 5px;
      p{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #FFFFFF;

      }
    }
  }
  &__buttonRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    &__balance{
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &__seeAll{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 98.1%;
      text-decoration-line: underline;
      text-decoration-color:  #FEA33C ;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      cursor: pointer;
      transition: ease-in-out all 0.2s;
      white-space: nowrap;

    }
    &__seeAll:hover{
      text-decoration-color:  #FFFFFF ;
      -webkit-text-fill-color: white;
      background: none;
    }
  }

  @media screen and (max-width:1300px ) {
    background-size: cover;
    //left:0;

  }
  @media screen and (max-width:1170px ){
    flex-direction: column;
    position: initial;
    left:0;
    padding: 50px 30px;
    width: 100%;
    background: black;
    &__imageBackground{
      display: none;
    }
    &__headerRow{
      p:last-child{
        text-align: right;
      }
    }
  }
  @media screen and (max-width:320px ){
    &__buttonRow{
      flex-direction: column;
    }
  }
}
