//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../src/fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../src/fonts/kabelctt-medium.woff') format('woff');
//}
.LockExpiryModalContainer{
  background: #000000;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 675px;
  width: 100%;
  height: 50%;
  padding: 55px 35px;
  position: relative;
  overflow:  hidden;

  &__close{
    position: absolute;
    width: 18px;
    height: 18px;
    cursor: pointer;
    right: 30px;
    top:30px
  }
  &__headerRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding-right: 30px;
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      text-transform: capitalize;
      color: #FFFFFF;
      width: 100%;
    }
    p:last-child{
      text-align: right;
    }
  }
  &__table{
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow-y: auto;
    padding-right: 20px;
    &__tableItem{
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: 60px;
      p{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #FFFFFF
      }
    }
  }
}
.LockExpiryModalContainer__table::-webkit-scrollbar {
  width: 10px;
}

.LockExpiryModalContainer__table::-webkit-scrollbar-track {
  border: 1px solid rgba(171, 129, 237, 0.13);
  border-radius: 10px;
  background: rgba(39, 45, 77, 0.24)
}

.LockExpiryModalContainer__table::-webkit-scrollbar-thumb {
  background: rgba(39, 45, 77, 0.24);
  border-radius: 20px
}

.LockExpiryModalContainer__table::-webkit-scrollbar {
  width: 10px
}

/* width of the entire scrollbar */
.LockExpiryModalContainer__table::-webkit-scrollbar-track {
  border: 1px solid rgba(171, 129, 237, 0.13);
  border-radius: 10px
}
.LockExpiryModalContainer__table::-webkit-scrollbar-thumb {
  background: linear-gradient(175.39deg, #FFD65A -7.46%, #DD8039 108.21%);
  border-radius: 20px
}
