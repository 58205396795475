.StatisticInfoItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 229px;
  width: 100%;
  &__label{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    background:url("../../images/withdraw/label.svg") no-repeat   ;
    background-size: cover;
    max-width: 230px;
    height: 48px;
    width: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 10px;

  }
  @media screen and (max-width: 1160px) {
    max-width: 100%;
  }
  @media screen and (max-width: 1200px) {
    &__label{
      max-width:100%;
      padding:0 5px;
      font-size: 15px;
    }


  }
  @media screen and (max-width: 600px) {
    max-width: 100%;

  }
}
