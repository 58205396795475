.DepositOverview{
  gap: 40px;
  &__titleBlock{
    max-width: 320px;
  }
  &__overviewContent{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    margin-bottom: 55px;
    &__overviewItem{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;

      .title{
        font-size: 16px !important;
      }
      &__amount{
        font-weight: 500;
        font-size: 16px;
        text-align: right;
        img{
          width: 20px;
          height: 20px;
        }
        &__usd{
          font-weight: 500;
          font-size: 14px;
          opacity: 0.8;
        }
      }
      span{
        font-weight: 700;
      }
    }
  }
  &__button{
    align-self: flex-end;
  }
}
