
.HeaderButton{
  position: relative;
  width: 188px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ease-in-out all 0.2s;

  &__background{
    position: absolute;
    right: 0;
    top:-3px
  }
  &__button{
    position: absolute;
    width: 100%;
    left: 0;


  }

  &__title{
    position: relative;

    padding-right: 10px;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 93.6%;
    /* identical to box height, or 17px */
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    text-shadow: 0px 5px 10px rgba(234, 30, 116, 0.5);
    //text-shadow: 0px 4px 10px #E49145;
  }

}
.HeaderButton_title{
  position: relative;

  padding-right: 10px;
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 93.6%;
  /* identical to box height, or 17px */
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #FFFFFF;
  text-shadow: 0px 5px 10px rgba(234, 30, 116, 0.5);
  //text-shadow: 0px 4px 10px #E49145;
  top:1px;
  transition: ease-in-out all 0.2s;
}
.Header_button{
  position: absolute;
  width: 100%;
  left: 0;
  top:1px;
  transition: ease-in-out all 0.2s;

}
.HeaderButton:hover {
  .Header_button{
    position: absolute;
    width: 100%;
    top:-3px;
    left: 0;
  }
  .HeaderButton_title{
    top:-3px
  }
}
.HeaderButton::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: transparent;
  top: 50%;
  left: 10%;
  width: 85%;
  height: 100%;
  transform: perspective(3em) rotateX(0deg) scale(1, 0.5);
  filter: blur(15px);
  opacity: 0.5;
  transition: ease-in-out all 0.2s;
}
.HeaderButtonRed:hover{
  &::before{
    background: #F2116B;
    opacity: 15%;
  }
}
.HeaderButtonGold:hover{
  &::before{
    background: #FFC56F;
    opacity: 20%;
  }
}
