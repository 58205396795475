//@font-face {
//    font-family: 'Hind Vadodara';
//    src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//    url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.ConnectButton {
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 160px;
    width: max-content;
    min-height: 55px;
    //-webkit-filter: blur(2px);
    &__reflection{
        -webkit-box-reflect: below 2px linear-gradient(transparent, rgba(0, 0, 0, 0.13));
    }
    &__title {
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 9px;

    }

    &__wrapper {
        position: absolute;
        //width: 100%;
    }
    &__wrapperMobile {
        position: absolute;

    }
    &__wrapperMobile:active{
        opacity: 0.5;
    }
    &__background {
        position: absolute;
        //width: 95%;
        top: 7px;
        //left: 5px;

    }
    &__menu{
        background: rgba(41, 41, 41, 0.8);
        opacity: 0;
        color: white;
        border: none;
        position: absolute;
        top: 110%;
        right: 0;
        z-index: 9999;
        width: 100%;
        display: flex;
        transition: all 0.1s linear;

        min-width: 250px;
        &__content{
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            //gap: 20px;
            &__item{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding:16px 12px;
                gap: 10px;
                font-family: 'Hind Vadodara';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 93.6%;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                font-feature-settings: 'pnum' on, 'lnum' on;
                color: #FFFFFF;
                white-space: break-spaces;
                word-break: break-all;
                transition: ease-in-out all 0.2s;
            }
            &__item:first-child{
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
            }
            &__item:last-child{
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;
            }
            img{
                width: 19px;
                height: 14px;
            }
            &__item:hover{
                background:black;
            }
        }


    }

    &__menu__item:hover{
        background:#131313;

    }
    &__activeMenu{
        display: flex;
        opacity: 1;
        //backdrop-filter: blur(2px);
        border-radius: 15px;
    }
    @media screen and (max-width: 330px){

            min-width: 150px;
            width: 100%;


    }
    @media screen and (max-width: 300px){

        max-width: 155px;
        width: 100%;


    }
}
//.CardButton {
//    cursor: pointer;
//
//    &__title {
//        color: white;
//        position: relative;
//        font-family: 'Hind Vadodara', arial;
//        font-style: normal;
//        font-weight: 500;
//        font-size: 18px;
//        text-transform: uppercase;
//        top: -35px;
//        left: 80px;
//    }
//
//    &__wrapper {
//        position: absolute;
//    }
//
//    &__background {
//        position: relative;
//        top: 5px;
//        left: 5px;
//    }
//}
