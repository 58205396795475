//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('/fonts/kabelctt-medium.ttf') format('truetype'),
//  url('/fonts/kabelctt-medium.woff') format('woff');
//}
.platformContainer {
  position: absolute;
  right: 0;
  bottom:0;
  top:25px;
  max-width: 945px;
  width: 100%;
  height: 463px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 3;
  @media screen and (max-width:1440px ){
    right: 0
  }
  @media screen and (max-width:1405px ){
    top:0
  }
  @media screen and (max-width:1235px ){
   position: relative;
    justify-content: center;
  }
  @media screen and (max-width:1000px ){
    position: initial;
    background: linear-gradient(156.29deg, #E3022C -27.21%, #F2106A 44.22%);
    border-radius: 40px;
    right: 0;
    max-width: 100%;
    align-items: center;
  }
  @media screen and (max-width:900px ){
    height: max-content;
  }
}
.imageBackground{
  position: absolute;
  z-index: -1;
  width: 100%;
  @media screen and (max-width:1300px ){

  }
  @media screen and (max-width:1000px ){
    display: none;
  }
}
.revenueBlock{
  max-width: 320px;

  width: 100%;
  margin-right: 35px;
  margin-left: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width:1440px ){
    //margin-right: 50px;
  }
  @media screen and (max-width:1235px ){
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    margin-right: 0;
    margin-left: 0;
  }
  @media screen and (max-width:800px ){
   justify-content: flex-start;
    gap: 30px;
  }
  @media screen and (max-width:500px ){
    padding:0;
    align-items: center;
    margin:auto;
  }
}
.labelBlock{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -50px;
  p{
    color:white;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }
  @media screen and (max-width:900px ){
    top:0
  }
}
.revenueTable{
  //margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  &__header{
    display: flex;
    align-self: flex-start;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: 25px;
    width: 80%;
    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      color: white;
      font-size: 18px;
      line-height: 126.1%;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: capitalize;
    }
  }
  &__tableItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 12px;
    img{
      width: 27px;
      height: 27px;
    }
    &__text{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-transform: capitalize;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      gap: 15px;
      span{
        color: white !important;
      }
    }
  }
  @media screen and (max-width:1400px ){

  }
  @media screen and (max-width:1300px ){
    &__header{
      justify-content: space-evenly;
      width: 100%;
      margin:0
    }
  }
  @media screen and (max-width:900px ){
   height: max-content;
    margin-top: 30px;
  }
  @media screen and (max-width:500px ){
    &__header{
      margin: 0;
      align-self: center;
    }
    &__tableItem{
      width: 95%;
    }
  }
}
.revenueTotalRow{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding-right: 20px;
  @media screen and (max-width:900px ){
    margin-bottom: 20px;
  }
  @media screen and (max-width:500px ){
    padding-right: 10px;
    padding-left: 10px;
    gap: 10px;
    //&__button{
    //  width: 100%;
    //}
  }
  @media screen and (max-width:320px ){
    flex-direction: column;
    align-items: center;

  }
}
.revenueTotalBlock{
  align-self: flex-start;
  &__text{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-bottom: 5px;
  }
  &__total{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 98.1%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width:320px ){
    align-self: center;
    text-align: center;
  }
}
