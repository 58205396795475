.CurrencyScreenWrapper{
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  &__content{
    background: #000000;
    border-radius: 40px;
    width: 100%;
    padding:56px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    gap: 32px;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;


  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    gap: 24px;
  }
}
