.CollateralSwitch {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  border-radius: 15px;
  width: 47px;
  height: 25px;
  padding: 3px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  cursor: pointer;
  &__statusCircle {
    display: flex;
    width: 45%;
    height: 85%;
    border-radius: 50%;
    background:transparent;
    transition: ease-in-out all 0.2s;
    position: absolute;
    left: 50%;
    right: 0;


  }

  &__trueStatus {
    transition: ease-in-out all 0.2s;
    background: linear-gradient(217.18deg, #13B366 5.68%, #15F087 74.05%);
    position: absolute;
    right: 0;

  }
  &__inactive{
    background:transparent;
  }
  &__falseStatus {
    background: linear-gradient(103.34deg, #E3022C 13.55%, #F2106A 83.74%);
    position: absolute;
   left: 3px;
  }
}


