.container {
  padding: 97px 29px 72px 29px;
  max-width: 1440px;
  margin: 0 auto;
@media screen and (max-width: 800px) {
  padding: 140px 8px 72px 8px;
}

}
//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('/fonts/kabelctt-medium.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Hind Vadodara Bold';
//  font-style: normal;
//  font-weight: 900;
//  src: url('/fonts/kabelctt-medium-bold.woff') format('woff')
//}
@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@400;500;600;700&display=swap');
.background {
  background: url("/images/manageMld/background.png") no-repeat center 100%;
  background-size: cover;

  //max-width: 1920px;
  width: 100%;
  //height: 100%;

}
input,
textarea,
select,
input:disabled,
textarea:disabled,
select:disabled,
input[type='search'],
input[type='date'],
input[type='date']:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
  opacity: 1;
  font-family: 'Hind Vadodara', sans-serif;
  background:none;

  &::placeholder {
    font-family: 'Hind Vadodara', sans-serif;

  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='text']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

a:focus,
a:active,
a:hover,
button::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
select::-moz-focus-inner,
input[type='file'] > input[type='button']::-moz-focus-inner {
  border: 0;
  outline: 0;
}
input:active, :hover, :focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important
}

input {
  border: none;
  background: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  transition: ease-in-out all 0.2s;
  outline: none;
  text-decoration: none;
  display: inline-block;
  position: relative;
  color: white;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: none;
  }
  .ReactModal__Overlay{
    background-color:rgba(255, 255, 255,0) !important;
  }

}
