//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}



.Pool2Container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  //height: 100vh;
  &__stakeInfoBlock{
    position: relative;
    width: 100%;

    &__imageBackground{
      position: absolute;
      right: -30px;
      top: 25px;
    }
  }
  &__stakeActionContainer{
    position: relative;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &__imageBackground{
      position: absolute;
      bottom: -25px;
      right:80px
    }
  }
  @media screen and (max-width: 1300px) {

    &__stakeActionContainer{
      gap: 10px;
      &__imageBackground{
        top:0;
         right: 0;
        width: 100%;
      }
    }

  }
  @media screen and (max-width: 1025px) {
    gap: 25px;
    &__stakeInfoBlock{
      &__imageBackground{
       display: none;
      }
    }
    &__stakeActionContainer{
      flex-direction: column;
      gap: 40px;
      &__imageBackground{
        display: none;
      }
    }

  }
  @media screen and (max-width: 500px) {
    max-width: 500px;
    height: 100%;
    padding-left: 6px;
    padding-right: 6px;

  }
}
