.infoLabelContainerDashboard{
  position: relative;
  max-width: 270px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  z-index: 5;
  top:-30px;
  justify-content: center;
}
.infoLabelContainer{
  position: relative;
  max-width: 270px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 15px;
  z-index: 5;
  top:-20px;
  justify-content: center;

  &__absoluteBlock{
    position: absolute;
    top: 0;
    left: -5px;
    max-width: 280px;
    width: 100%;
    z-index: -1;
  }
  &__backgroundLabel{
    position: absolute;
    top:0;
    left:13px;
    width: 97%;
    z-index: -1;
  }
  &__infoLabel{
    max-width: 270px;
    width: 100%;
  }
  &__text{
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    color: white;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    white-space: nowrap;

  }
  //@media screen and (max-width: 1200px){
  //  max-width: 230px;
  //}
  @media screen and (max-width: 360px){
    max-width: 230px;
  }
  @media screen and (max-width: 300px){
    padding-top: 10px;
    //max-width: 200px;
    &__title{
      font-size: 12px;
    }
  }
}


