.DepositContainer{
   width: 100%;
   &__Deposit{
      background: #000000;
      border-radius: 40px;
      padding: 0 56px 40px 56px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      &__header{
         display: flex;
         flex-direction: column;
         gap: 30px;
      }
      &__headerRow{
         display: flex;
         flex-direction: row;
         align-items: flex-end;
         &__icon{
            position: absolute;
            right: 30px;
            bottom: 10px;
         }
         &__title{
            font-family: 'Hind Vadodara';
            font-style: normal;
            font-weight: 700;
            font-size: 34px;
            line-height: 103.1%;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            position: relative;
            margin-bottom: 5px;
            img{
               position: absolute;
               left:-20px;
               top:-10px
            }
         }
         &__availableDeposit{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background:url("../../images/dashboard/availableBlock.svg") no-repeat 100%;
            background-size: contain;
            font-family: 'Hind Vadodara';
            font-weight: 500;
            font-size: 16px;
            line-height: 93.6%;
            letter-spacing: -0.04em;
            text-transform: uppercase;
            color: #FFFFFF;
            padding:13px 23px;
            margin-left: 20px;
         }
         @media screen and (max-width:500px ){
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            &__icon{
               right: 0;
            }
         }
      }
      &__searchBlock{
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         width: 100%;
         &__input{

         }
         &__filterButtons{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            width: 100%;
            &__button{
               max-width: 135px;
               width: 100%;
            }

         }
      }
      @media screen and (max-width:900px ){
         padding:0;
         gap: 8px;
         background:transparent;
         &__header{
            background:#000000;
            padding:32px;
            padding-top: 0;
            border-radius: 40px;
         }
         &__searchBlock{
            flex-direction: column;
            gap: 16px;
            &__input{
               max-width: 100% !important;
            }
            &__filterButtons{
               &__button{
                  max-width: 100%;
               }
            }
         }
      }
      @media screen and (max-width:380px ){
         &__searchBlock{
            &__filterButtons{
               flex-direction: column;
               &__button{
                  max-width: 100%;
               }
            }
         }
      }
   }
}
