.TokenLockPanel {
  position: relative;
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__background {
    position: absolute;
    background: linear-gradient(107.42deg, #FEA23C 40.39%, #FED583 102.19%);
    border: 1px solid #FFB969;
    border-radius: 12px;
    width: 90%;
    height: 244px;

  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    background: #000000;
    border-radius: 40px;
    padding: 56px 120px;
    min-height: 228px;

    &__title {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

  }

  &__subheader {
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    font-size: 28px;

    text-align: center;
    font-family: Hind Vadodara, sans-serif;

    color: white;

    &--bold {
      font-family: Hind Vadodara;
      white-space: nowrap;
      font-weight: 700;
    }

    &--purple {
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;

      background: linear-gradient(170.33deg, #F36D0F 7.28%, #F8D48F 35.52%, #FFDFAD 63.19%, #FA9728 82.1%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    &--big-font {
      font-size: 28px;
    }
  }

  &__buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;


  }

  &__buttons-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

    p {
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500 !important;
      font-size: 16px;
      color: white;

      b {
        font-weight: 500;
      }
    }

    img {
      width: 31px;
      height: 31px;
    }
  }

  &__buttons-item--apr {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 98.1%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media screen and(max-width: 1024px) {
    &__content {
      padding: 56px;
    }
  }
  @media screen and(max-width: 900px) {
    &__content {
      &__title {
        align-items: center;

      }

      flex-direction: column;
      gap: 20px;
    }
  }
  @media screen and(max-width: 800px) {
    padding: 0 9px 0 9px;
    &__background {
      display: none;
    }
  }
  @media screen and(max-width: 600px) {
    &__content{
      padding: 32px;
    }
    &__buttons-container {
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }
  }
  @media screen and(max-width: 500px) {
    &__subheader {
      font-size: 24px;
    }
    &__content{
      padding: 32px;
    }
    &__buttons-container {
     display: flex;
      flex-direction: column;

    }
    &__buttons-item{
      justify-content: space-between;
    }
  }
  @media screen and(max-width: 330px) {
    &__subheader {
      font-size: 18px;
    }
    &__subheader--purple{
      font-size: 20px;
    }
  }
}
