.TableMobileItem{
  width: 100%;
  background: #000000;
  display: none;
  border-radius: 35px;
  padding: 8px;
  @media screen and (max-width: 600px){
    display: block
  }
  &__content{
    padding: 32px 24px;
    background: #131313;
    border-radius: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  &__infoBlock{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    &__infoRow{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 93.6%;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF !important;
      &__header{
        opacity: 0.9;
        width: 100%;
      }
    }
    &__infoRow:first-child{
      align-items: center;
    }
    &__token{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
       p{
         font-family: 'Hind Vadodara'  !important;

         font-weight: 500;
         font-size: 18px !important;
         color: #FFFFFF !important;
       }

      img{
        width: 47px;
        height: 47px;
      }
    }
    &__balance{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      //gap: 25px;
      &__value{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        text-transform: capitalize;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        align-items: center !important;
        p{

          font-size: 24px !important;
        }

      }

    }
    &__apy{
      width: 100%;
      display: flex;
      flex-direction: column;
      font-size: 24px;
      gap: 7px;
      align-items: center;
      p{
        font-size: 20px !important;
      }
      &__percent{
        background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 20px;

      }
      &__apr{

        font-size: 18px;
        border: 1px solid #313131;
        backdrop-filter: blur(2px);
        border-radius: 31px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:14px 16px;
        gap: 6px;
        width: max-content;
        &__circle{
          background: #FFD65A;
          width: 10px;
          height: 10px;
          border-radius: 50%;

        }
      }

    }
    &__collateral{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }
  &__buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
  }

  @media screen and (max-width: 340px){
    &__infoBlock {
      &__token{
        img{
          width: 30px;
          height: 30px;
        }
      }
      &__infoRow {
        font-size: 14px;

      }
      &__balance__value{
        font-size: 22px;
      }
    }
  }
}
