.DashboardMldRewardsContainer{
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  //padding: 30px 30px 20px;
  padding: 10px 30px 20px;
  gap: 23px;
  flex-direction: column;

  &__text{
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
  }
  &__valueBlock{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    &__usd{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      display: flex;
      flex-direction: row;

      div {
        margin-left: 2px;
        margin-right: 10px;
      }
    }
    &__value{
      font-family: 'Hind Vadodara';
      font-weight: 700;
      font-size: 40px;
      line-height: 98.1%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      text-align: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      gap: 15px;
      img{
        margin-right: 0 !important;
      }
    }
    @media screen and(max-width: 400px){
      &__value{
        font-size: 32px;
      }
    }
  }
  &__buttons{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 13px;
    &__button  {
      max-width: 165px;
      width: 100%;
      p {
        //gap: 10px;
        //margin-right: 10px;
      }
    }
  }
  //&__buttons > * + * {
  //  margin-top: 13px;
  //
  //}
}
//.DashboardMldRewardsContainer > * + * { margin-top: 23px;}
