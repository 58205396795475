.TableMainItem{
  width: 100%;
  padding:36px 56px;

  background: #000000;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: ease-in-out all 0.2s;
  cursor: pointer;
  //overflow-x: auto;
  //justify-content: space-between;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  &__value{

    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    text-transform: capitalize;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    span{
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 100.6%;
      text-transform: uppercase;
      background: linear-gradient(162.73deg, #F5D153 48.56%, #DD8039 66.05%, #FFD65A 74.35%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;

    }
  }
  &__asset{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;

    img{
      width: 47px;
      height: 47px;
    }
    p,b{
      font-family: 'Hind Vadodara' !important;
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 93.6% !important;
      text-transform: uppercase !important;
      color: #FFFFFF !important;
    }
  }
  &__marketSize{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

  }
  &__apy{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 98.1%;
    text-align: center;
    color: #FFFFFF;
    gap: 7px;
    &__percent{
      background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 20px;
    }
    &__apr{
      border: 1px solid #313131;
      backdrop-filter: blur(2px);
      border-radius: 31px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding:14px 16px;
      gap: 6px;
      &__circle{
        background: rgba(255, 255, 255, 0.2);
        width: 10px;
        height: 10px;
        border-radius: 50%;

      }
    }

  }
  &__collateral{
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-size: 16px;
    line-height: 98.1%;
    text-align: center;
    color: #FFFFFF;
    &__status{
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      border-radius: 15px;
      width: 47px;
      height: 25px;
      padding:3px;
      display: flex;
      flex-direction: row;
      position: relative;
      &__statusCircle{
        display: flex;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        position: absolute;
      }
      &__trueStatus{
        background: linear-gradient(217.18deg, #13B366 5.68%, #15F087 74.05%);
        right: 3px;
      }
      &__falseStatus{
        background: linear-gradient(103.34deg, #E3022C 13.55%, #F2106A 83.74%);
        left: 3px;
      }
    }
  }
  &__loopApr {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 98.1%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;

  }
  @media screen and (max-width: 800px) {
    width: 1262px;
  }

}
.TableMainItem:hover{
  background: #1f1f1d;
}

