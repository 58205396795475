.BorrowTokenContainer{
  background: #000000;
  border-radius: 40px;
  //padding: 50px 55px 55px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  max-width: 675px;
  width: 100%;
  &__headerBlock{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    //flex-wrap: wrap;
    align-items: center;
    width: 100%;
    text-align: left;
    &__text{
      width: 100%;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      max-width: 230px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.8;

    }
    &__token{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 23px;
      img{
        width: 35px;
        height: 35px;
      }
      p{
        font-family: 'Hind Vadodara';
        font-style: normal;
        //white-space: nowrap;
        font-weight: 700;
        font-size: 34px;
        line-height: 103.1%;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }
  &__inputBlock{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 12px;
    &__labelRow{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 19px;
      align-items: center;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      &__value{
        font-weight: 700;
        font-size: 24px !important;
        line-height: 98.1%;
        text-align: center;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: flex;
        flex-direction: row;
        gap: 5px;
        p{
          font-size: 24px !important;
        }
      }
    }
  }
  &__buttonsRow{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &__button{}
  }
  @media screen and (max-width: 900px){
    padding:30px;
    padding-top: 0;
    max-width: 100%;
    &__headerBlock{
      flex-direction: column;
      text-align: center;
      align-items: center;
      gap: 16px;
      &__token{
        flex-direction: column;
        position: relative;
        gap: 15px;
        img{
          position: relative;
          top:-10px
        }
      }
      &__text{
        max-width: 250px;
      }
    }
    &__buttonsRow{
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 32px;
      &__button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
