.secondaryLabel{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  max-width: 210px;
  width: 100%;
  height: 60px;
  left: 53px;
  top: -30px;
  &__imgBlock{
    position: absolute;
    z-index: -1;
    width: 100%;
  }
  @media screen and (max-width:500px ){
    left:20px
  }
}
