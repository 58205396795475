.DashboardTableContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  min-width: 1262px;

  &__content{
    background: #131313;
    border-radius: 12px;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  table{
    width: 100%;
    flex-direction: column;
    display: flex;
    gap: 8px;

  }
  @media screen and (max-width: 900px){
    min-width: 100%;
    width: 100%;
    &__content{
      border-radius: 35px;
      padding: 0;
      display: flex;
      flex-direction: column;
      //gap: 8px;
      background:transparent;
    }
  }
}
