.CircularProgress{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: max-content;

  border-radius: 50%;
  svg{
    transform: rotate(-90deg);
  }

  p {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.04em;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    position: absolute;
    margin:0 auto;
    left:0;
    right: 0;
    max-width: 65px;

  }
  img{
    margin:0
  }


  &__svg-indicator-track,&__svg-indicator-indication {

    fill: transparent;

  }

  &__svg-indicator-track {
    stroke: #151515;
  }

  &__svg-indicator-indication {
    //stroke: #FED280;

  }
}
