.ZeroBalanceInfo {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__TitleBlock {
    background: #000000;
    border-radius: 40px;
    width: 100%;
    padding: 64px 45px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    text-align: center;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;

    &__title {
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      text-transform: uppercase;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    &__backButton{
      align-self: flex-end;
    }
  }
  &__balanceInfo{
    width: 100%;
    background: #000000;
    border-radius: 40px;
    padding: 40px 45px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__rowItem{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      align-items: center;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      p{
        display: flex;
        align-items: center;
        gap: 8px;
      }
      span{
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &__TitleBlock {
      &__backButton{
        align-self: center;
      }
    }
  }
}
