.DashboardHeaderContainer{
  background: #131313;
  border-radius: 50px;
  width: 100%;

  flex-direction: row;
  padding:18px 56px;
  height: 51px;
  align-items: center;
  display: flex;

  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 900px){
    display: none;
  }
}

