//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../../../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../../../../fonts/kabelctt-medium.woff') format('woff');
//}
.lockBalanceContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 4;
  min-height:370px ;
  width: 100%;
  background: url("../../../../../images/manageMld/lockMld/lockMldContainer.svg") no-repeat 100%  ;
  background-size: cover;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  &__labelRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    img{
      width: 33px;
      height: 33px;
    }
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 93.6%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
  &__imageBackground{
    position: absolute;
    z-index: -1;
    max-width: 973px;
    width: 100%;
    object-fit: contain;
  }
  //@media screen and (max-width:1300px ){
  //
  //  background: #000000;
  //  border-radius: 40px;
  //  background-size: cover;
  //  width: 100%;
  //  margin-right: 10px;
  //}
  @media screen and (max-width:1170px ){

    &__imageBackground{
      display: none;
    }
    padding-top: 60px;
    background: #000000;
    border-radius: 40px;
    margin-right:0;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width:500px ){
    &__imageBackground{
      display: none;
    }
    padding-top: 60px;
    background: #000000;
    border-radius: 40px;
    max-width: 500px;
    height: 100%;
    width: 100%;
  }
}

.lockBalanceContent{
  display: flex;
  padding: 55px 80px 55px 60px;
  flex-direction: column;
  gap: 37px;
  &__lockBalanceLabelRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__aprValue{
    max-width: 175px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
      position: absolute;
      z-index: -1;
    }
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 26px;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
  &__contentRow{
    display: flex;
    flex-direction: row;
    gap: 30px;
    &__amountBlock{
      display: flex;
      flex-direction: column;

      &__title{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        text-transform: capitalize;
        color: #FFFFFF;
      }
      &__balanceRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        img{
          align-self: center;
        }
        span{
          font-size: 20px;
          align-self: flex-end;
        }
      }
      &__balance{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 98.1%;

        background:red;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        white-space: nowrap;

        span{
          font-size: 20px;
          align-self: flex-end;
        }
      }
    }

    &__column1{
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 100%;


      &__walletBalanceRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        &__labelRow{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        &__title{
          font-family: 'Hind Vadodara';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          text-transform: capitalize;
          color: #FFFFFF;
        }
        &__balance{
          align-items: flex-start !important;
          p {
            font-family: 'Hind Vadodara', sans-serif !important;
            font-style: normal;
            font-weight: 700;
            font-size: 40px !important;
            line-height: 98.1%;
            display: flex;
            align-items: center;
            font-feature-settings: 'pnum' on, 'lnum' on;
            background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

          }
        }
      }
    }
    &__column2 {
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.8;
      max-width: 270px;
      width: 100%;
      padding-right: 20px;
      span{
        color: #FEC066;
      }
    }
  }
  &__mobileText {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    opacity: 0.8;
    max-width: 270px;
    width: 100%;
    padding-right: 20px;
    display: none;
  }
  @media screen and (max-width: 1200px) {
    &__contentRow {
      &__column1 {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;


        &__walletBalanceRow {

          &__labelRow {
            align-items: flex-start;
          }
        }
      }
    }
  }
  @media screen and (max-width:1170px ){

    padding:30px;

    &__contentRow {
      flex-direction: column;
      &__column2{
        max-width: 100% !important;
        text-align: center;
      }
    }
  }
  @media screen and (max-width:800px ){

    &__contentRow{
      flex-direction: column;
      &__column1{
        flex-direction: column;
        width: 100%;
        &__walletBalanceRow{
          flex-direction: column;
          width: 100%;
          justify-content: center;
          &__labelRow{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 30px;
          }
          &__button{
            align-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }

    }

  }
  @media screen and (max-width: 1300px) {
    padding: 55px 80px 55px 30px;
  }
  @media screen and (max-width: 1200px) {

    padding: 55px 80px 55px 60px;
  }
  @media screen and (max-width:500px ){
    padding: 0 33px 33px;
    gap: 25px;
    &__mobileText {
      display: block;
    }
    &__lockBalanceLabelRow{

      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    &__contentRow{
      flex-direction: column;
      &__column1{
        flex-direction: column;
        width: 100%;

        &__walletBalanceRow{
          flex-direction: column;
          width: 100%;
          justify-content: center;

          &__labelRow{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 30px;
          }
          &__button{
            align-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
          }
        }
      }
      &__column2 {
        display: none;
      }
    }

  }

}
.lockBalanceTitle{
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  opacity: 0.8;
  max-width: 311px;
}
