.NetworkButton {
    cursor: pointer;
    height: 49px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &__title {
        color: white;
        position: absolute;
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 93.6%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 11px;
        img {
            margin-right: 11px;
        }
    }

    &__wrapper {
        position: absolute;
    }

    &__background {
        position: relative;
        top: 5px;
        left: 5px;
    }
}
