
.DashboardHealthFactorContainer{
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 40px 45px 20px;
  gap: 20px;
  flex-direction: column;
  &__infoValues{
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 27px;
    width: 100%;
    &__valueRow{
      width: 100%;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 16px;
      line-height: 93.6%;
      align-items: center;
      color: #FFFFFF;
      &__value{
        white-space: nowrap;
      }
    }
  }
  //&__infoValues > * + *{
  //  margin-top: 27px;
  //}
  @media screen and (max-width: 1200px){
    &__infoValues {
      padding-top: 10px !important;
    }
  }
  @media screen and (max-width: 360px){
    padding-right: 30px;
    padding-left: 30px;
  }
}
//.DashboardHealthFactorContainer > * + * {
//  margin-top: 40px;
//
//}
