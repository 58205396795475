//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('/fonts/kabelctt-medium.ttf') format('truetype'),
//  url('/fonts/kabelctt-medium.woff') format('woff');
//}
.LoopingBlock{
  position: relative;
  background: #000000;
  border-radius: 40px;
  padding: 64px;
  padding-top: 56px;
  max-width: 675px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__headerLabel{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:64px;
    &__lineShining{

      position: absolute;
      left:-40px;
      top:-35px;
      @media screen and (max-width:500px ){
        left: -40px;
        height: 205px;
      }
    }
    &__redBookMark{
      max-width: 87px;
      width: 100%;

    }
    &__manageChest{
      position: absolute;
      right: -20px;
      bottom: -5px;

    }
  }
  &__titleBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(137.62deg, #F36D0F -9.46%, #F8D48F 31.82%, #FFDFAD 72.24%, #FA9728 99.89%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    &__text{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      opacity: 0.8;
      max-width: 331px;
    }
  }
  &__amountRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    &__value{
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__label{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 18px;
        line-height: 19px;
        text-transform: capitalize;
        color: #FFFFFF;
      }
      &__balance{
        font-family: 'Hind Vadodara';
        font-weight: 700;
        font-size: 32px !important;
        line-height: 98.1%;
        text-align: center;
        letter-spacing: -0.02em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(348.57deg, #FEA33C 12.8%, #FED280 63.24%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        p{
          font-size: 32px !important;
          //gap: 10px !important;
          align-items: flex-end;
          -webkit-align-items: flex-end;
        }
      }
    }
  }
  &__percentValues{
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__rowValue{
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      p{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 93.6%;
        display: flex;
        align-items: center;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
      }
      &__percent{
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 93.6%;
        display: flex;
        align-items: center;
        text-align: right;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(180deg, #FDDCA4 0%, #FCB158 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      &__tokenName{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        img{
          width: 21px;
          height: 21px;
          margin:0 !important;
        }
      }
    }
  }
  &__buttonRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 130%;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      opacity: 0.8;
      max-width: 250px;
    }
    &__button{

      &__desktop{
        display: flex;
      }
      &__mobile{
        width: 100%;
        display: none;
      }
      @media screen and (max-width: 900px) {
        &__desktop{
          display: none;
        }
        &__mobile{
          width: 100%;
          display: flex;
        }
      }
    }
  }
  @media screen and (max-width: 1300px) {
    &__titleBlock{
      gap: 30px;
    }
  }
  @media screen and (max-width: 1170px) {
    &__titleBlock{
      align-items: flex-end;
    }
  }
  @media screen and (max-width: 1000px) {

    padding: 0 33px 50px;
    &__titleBlock{
      align-items: center;
    }
    &__headerLabel{
      position: relative;
      left:0;
      width: max-content;
      align-self: center;
    }
  }
  @media screen and (max-width: 900px){
   max-width: 100%;

    &__buttonRow{
      flex-direction: column;
      align-items: center;
      gap: 24px;
      text-align: center;
      &__button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 400px){
    padding: 0 25px 50px;
    &__amountRow{
      &__value__balance{
        p{
          font-size: 26px !important;
        }


      }
    }
  }
}
.Borrow1ClickLoopForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #120d48;
  padding: 12px;
  button{
    transition: all 0.2s ease 0s;
  }
  &__title {
    margin-bottom: 20px;
    color: white;
    font-weight: 600;
    font-size: 23px;
  }

  &__description {
    width: 100%;
    max-width: 660px;
    text-align: center;
    color: #8d8f99;
    margin-bottom: 20px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    width: 100%;

    p {
      font-size: 12px;
      text-align: center;
    }
  }

  &__select {
    margin-top: 20px;

    p {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__select-button {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    color: white;
    background:green;
    span {
      color: white;
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__status-label {
    b {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
