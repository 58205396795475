.Header {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  height: 91px;
  //max-width: 1440px;
  width: 100%;
  z-index: 90;
  transition: ease-in-out all 0.2s;

  &__headerContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-family: 'Hind Vadodara';
    font-weight: 500;
    font-style: normal;
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    //padding-right: 20px;
  }

  &__headerContentMobile {
    display: none;
  }

  &__logo {
    position: relative;
    z-index: 5;

    img {
      margin-top: 15px;
      width: 150px;

    }
  }

  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: start;
    font-weight: 500;
    font-size: 14px;
    line-height: 166.1%;
    position: relative;
    align-items: center;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #646464;
    //margin-left: 108px;

    &__item {
      cursor: pointer;
      //width: 101px;
      padding: 9px 20px;
      //padding-right: 0;
      //padding-left: 0;
      text-align: center;
      transition: ease-in-out all 0.2s;
      position: relative;
      white-space: nowrap;
    }

    &__item:hover {
      color: white;

      ellipse {
        fill: white;
      }
    }

    &__dots {
      cursor: pointer;
      //margin-left: 32px;
      padding: 9px 20px;
      display: none;
      position: relative;

      ellipse {
        transition: ease-in-out all 0.2s;
      }
    }

    &__dots.active {
      ellipse {
        fill: white;
      }
    }

    &__item.active {
      font-weight: 700;
      color: #FFFFFF;
      background: #292929;
      border-radius: 50px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    &__more {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 20px;
    }

    &__dropdown {
      background: rgba(41, 41, 41, 0.8);
      position: absolute;
      z-index: 100;
      left: -40px;
      top: 70px;
      display: flex;
      opacity: 0;
      flex-direction: column;
      width: 150px;
      transition: ease-in-out all 0.2s;
      padding: 10px;
      gap: 15px;
      border-radius: 8px;

      &__item {
        cursor: pointer;
        transition: ease-in-out all 0.2s;
        text-align: center;
      }
      &__item_close {
        display: none;
      }
      &__item:hover {
        color: white;
      }

      &__item.active {
        font-weight: 700;
        color: #FFFFFF;
        background: #292929;
        border-radius: 50px;
        padding-top: 9px;
        padding-bottom: 9px;
      }

      &__item:nth-child(1), &__item:nth-child(2), &__item:nth-child(3), &__item:nth-child(4), &__item:nth-child(5) {
        display: none;
      }
    }

    &__dropdown.open {
      opacity: 1;
    }
  }


  &__account {

    display: flex;
    flex-direction: row;
    //flex-grow: 1;
    align-items: center;

    &__buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__button:nth-child(1) {
      margin-right: 16px;
    }
  }

  &__balance {
    display: flex;
    flex-direction: row;
    //width: 79px;
    height: 40px;
    align-items: center;
    gap: 15px;
    margin-right: 28px;

    &__logo {
      display: flex;
      flex-direction: row;
      margin-right: 15px;
    }

    &__value {
      color: #FFFFFF;
      text-transform: capitalize;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-weight: 700;
      font-size: 16px;
      line-height: 166.1%;
    }
  }

  &__MobileHeader {
    background: rgba(0, 0, 0, 0.3);
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    width: 100%;
    position: absolute;
    transition: ease-in-out all 0.5s;
    top: 99%;
    padding: 20px 8px 15px 8px;
  }

  &__MobileHeader.open {
    transition: all 0.5s ease;
    animation: gradientBackground 0.5s ease;
    background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
  }

  @media screen and (max-width: 1415px) {
    &__menu {
      &__dots {
        display: flex;
      }
    }
    &__menu__item:nth-child(7) {
      display: none;
    }
    &__menu__item:nth-child(6) {
      display: none;
    }
    //&__headerContent {
    //  padding-left: 50px;
    //}

  }
  @media screen and (max-width: 1270px) {
    &__menu__item:nth-child(5) {
      display: none;
    }
    &__menu__dropdown__item:nth-child(5) {
      display: block;
    }
  }
  @media screen and (max-width: 1170px) {
    &__menu__item:nth-child(4) {
      display: none;
    }
    &__menu__dropdown__item:nth-child(4) {
      display: block;
    }
  }
  @media screen and (max-width: 1080px) {
    &__menu__item:nth-child(3) {
      display: none;
    }
    &__menu__dropdown__item:nth-child(3) {
      display: block;
    }
  }
  @media screen and (max-width: 1000px) {
    &__menu__item:nth-child(2) {
      display: none;
    }
    &__menu__dropdown__item:nth-child(2) {
      display: block;
    }
  }
  @media screen and (max-width: 900px) {
    //display: none;
    &__MobileHeader {
      display: flex;
    }
    &__headerContent {
      display: none;
    }
    &__headerContentMobile {
      background-color: #000000;
      height: 91px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &__img {
        align-self: flex-end;
        margin-bottom: -10px;
        height: 76px;
        width: 180px;
        z-index: 10;
      }
    }
    &__logo {
      position: absolute;
      bottom: -30px;

      img {
        object-fit: cover;
        margin-top: 0;
      }

    }
    &__menu, &__account {
      display: none;
    }
  }
  @media screen and(max-width: 500px) {
    &__headerContentMobile {

      &__img {
        display: none;
      }
    }

  }
  @media screen and(max-width: 320px) {
    &__headerContentMobile {
      padding-left: 5px;
       padding-right: 0;
    }
    &__chain {
      max-width: 120px !important;
      p{
        font-size: 12px ;
      }
    }

  }
}

//.Header.open{
//
//  animation-name: gradientBackground;
//  animation-duration: 0.5s;
//  //animation-fill-mode: forwards;
//  //animation-timing-function: steps(1, end);
//  background: linear-gradient(102.97deg, #E3022C 13.62%, #F2106A 84.19%);
//  position: fixed;
//
//}

//@media screen and (max-width: 800px) {
//  .MobileHeader{
//    display: flex;
//  }
//
//}
