.LiquidityMiningAPYLine {
  position: relative;
  margin-top: 3px;
  border-radius: 2px;
  padding: 3px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: white;
  &.noBorder {
    border: none;
    box-shadow: none;
  }

  &__apr{
    border: 1px solid #313131;
    backdrop-filter: blur(2px);
    border-radius: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:14px 16px;
    gap: 6px;
    &__circle{
      background: rgba(255, 255, 255, 0.2);
      width: 10px;
      height: 10px;
      border-radius: 50%;

    }
    &__activeCircle{
      background: #FFD65A;
      width: 10px;
      height: 10px;
      border-radius: 50%;

    }
  }

  &__withTooltip {
    cursor: pointer;
  }

  &__tribe {
    display: flex;
    align-items: center;
    justify-content: center;

    strong {
      margin: 0 3px;

    }

    img {
      width: 12px;
      height: 12px;
      position: relative;

    }
  }

  .ValuePercent .ValuePercent__value,
  &__title {
    font-size: 14px;

  }

  &__title {
    font-weight: bold;
  }

  .ValuePercent {
    margin: 0 3px;
  }

  .TokenIcon {
    &__image {
      margin-right: 0;
    }
  }

  .LiquidityMiningAPYLine__tooltip {
    max-width: 550px;
    display: block;
    padding: 7px 10px;
    border-radius: 50%;


    opacity: 1;
  }

  &__tooltip--content {
    font-size: 14px;

  }
}
