.RepayContainerBackground{
  height: 100%;
  @media screen and (max-width: 800px) {
    height: initial;
  }
}
.RepayContainer{
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  &__imageBackground{
    position: absolute;

    right: 35px;
    top:22%
  }
  @media screen and (max-width: 1160px) {
    flex-direction: column;
    gap: 8px;
    &__imageBackground{
      display: none;
    }
  }
}
