.BlockLabel{
  position: relative;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background:red;
  max-width: 190px;
  width: 100%;
  &__lineShining{
    position: absolute;
    //@media screen and (max-width:500px ){
    //  left: -40px;
    //  height: 205px;
    //}
  }
  &__BookMark{
    max-width: 87px;
    width: 100%;
  }
  &__icon{
    position: absolute;
    right: 0px;
    bottom: -5px;
  }
  @media screen and (max-width:500px ){
    max-width: max-content;
  }
}
