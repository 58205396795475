.SelectChainFieldWrapper{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 140px;
  width: 100%;
  background:url("../../../images/selectTokenField/selectTokenField.svg") no-repeat 100%;
  background-size: contain;

  padding: 19px 14px;
  padding-right: 22px;
  cursor: pointer;
  transition: all 0.2s linear;
  &__active{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 8px 8px 0 0;
    transition: all 0.2s linear;
  }
  &__SelectChainField {
    border-radius: 8px;
    background:transparent;
    border:none !important;
    z-index: 1000;
    width: 100%;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 93.6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding:0;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;

    &__selectChain{
      width: 20px;
      height: 20px;
    }
  }
  &__menu{
    background: rgba(41, 41, 41, 0.8);
    opacity: 0;
    color: white;
    border: none;
    position: absolute;
    top: 110%;
    right: 0;
    z-index: 9999;
    width: 100%;
    display: flex;
    transition: all 0.1s linear;
    padding:16px 12px;
    max-height: 140px;
    &__content{
      overflow-y: auto;
      width: 100%;
      align-items: center;
      flex-direction: column;
      display: flex;
      gap: 16px;
      &__item{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 10px;
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 93.6%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
      }
      img{
        width: 20px;
        height: 20px;
      }
    }


  }

  &__menu__item:hover{
    background:#131313;

  }
  &__activeMenu{
    display: flex;
    opacity: 1;
    //backdrop-filter: blur(2px);
    border-radius: 15px;
  }
}
.SelectChainFieldWrapper__menu__content::-webkit-scrollbar {
  width: 3px;

}

/* Track */
.SelectChainFieldWrapper__menu__content::-webkit-scrollbar-track {

  border-radius: 10px;
  background:#414040;
}

/* Handle */
.SelectChainFieldWrapper__menu__content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D9D9D9;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

