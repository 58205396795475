//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.QuickLockModalContainer{
  background: #000000;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 675px;
  width: 100%;
  padding: 40px;
  padding-top: 0;
  position: relative;
  &__close{
    position: absolute;
    width: 18px;
    height: 18px;
    cursor: pointer;
    right: 30px;
    top:30px;
    z-index: 20;
  }
  &__labelBlock{
    display: flex;
    align-items: center;

    position: absolute;
    &__bookMark{
      position: relative;
      top:-1px;

    }
    &__shining{
      position: absolute;
      left:-30px;
      top:-30px;
      max-width: 190px;

    }
    &__bag{
      position: absolute;
      top:20px;
      left:40px
    }
  }
  &__headerBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    margin-top: 40px;
    &__title{
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 103.1%;
      /* or 35px */

      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      background: linear-gradient(129.91deg, #F36D0F -0.14%, #F8D48F 35.69%, #FFDFAD 70.78%, #FA9728 94.78%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }
    &__text{
      font-family: 'Hind Vadodara';
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      max-width: 400px;
      opacity: 0.8;
    }
  }
  &__stepContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #131313;
    border-radius: 12px;
    gap: 40px;

    &__stepRow{
      background: #2F2F2F;
      border-radius: 50px;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      &__button{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding:10px;
        p{
          font-family: 'Hind Vadodara';
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          color: #FFFFFF;
        }
      }
      &__buttonActive:first-child{
        background: linear-gradient(101.7deg, #FEA23C 40.88%, #FED583 81.83%);
      }
      &__buttonActive:last-child{
        background: linear-gradient(101.7deg, #FED583 40.88%, #FED583 81.83%);
      }
      &__button:first-child{
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;

      }
      &__button:last-child{
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;

      }
    }
    &__stepContent{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding:45px;
      padding-top: 0;
      &__textBlock{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__stepTitle{
        font-family: 'Hind Vadodara';
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
      }
      &__stepText{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        opacity: 0.8
      }
    }
    &__stepErrorContent{
      display: flex;
      flex-direction: column;
      padding:45px;
      padding-top: 0;
      gap: 24px;
      &__textBlock{
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      &__stepTitle{
        font-family: 'Hind Vadodara';
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
      }
      &__stepText{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        opacity: 0.8;
        line-height: 17px;
      }
      &__buttonRow{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

      }
    }

  }
  @media screen and (max-width: 800px) {
    &__labelBlock{
      position: relative;
      justify-content: center;
      width: 100%;
      &__shining{

        left:35%;
        top:-35px;

      }
      &__bag{

        top:20px;
        left:50%
      }
    }
    &__headerBlock{
      margin-top: 0;
    }
  }
  @media screen and (max-width: 500px) {
    padding-right:5px;
    padding-left:5px;

    &__labelBlock{
      justify-content: center;
      width: 100%;
      &__shining{
        left:30%;
        top:-35px;
        max-width: 145px;
        width: 100%;height: 158px;
      }
      &__bookMark{
        width: 60px;
        height: 81px;
      }
      &__bag{
        width: 52px;
        height: 68px;
        top:15px;
        left:50%
      }
    }
    &__stepContainer{
      &__stepContent{
        flex-direction: column;
        gap: 30px;
        padding:24px
      }
      &__stepErrorContent{
        padding:32px;
        padding-top: 0;
        &__buttonRow{
          flex-direction: column;
        }
      }
    }

  }
  @media screen and (max-width: 380px) {
    &__labelBlock{
      justify-content: center;
      width: 100%;
      &__shining{
        left:25%;
        top:-35px;


      }

    }


  }
}
