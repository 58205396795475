.MainPageBackground{
    background: url("../../../../images/pages/main/bg-main-page.png") no-repeat  100%;
    background-size: cover;
    width: 100%;
}
.MainPageWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}
.transition-fade {
    transition: 0.4s;
    opacity: 1;
}

html.is-animating .transition-fade {
    opacity: 0;
}
.MainPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1440px;
    width: 100%;
    padding:0 25px 0 25px;
    gap: 40px;
    &__header {
        background-image: url('../../../../images/pages/main/title-image.svg');
        width: 100%;
        height: 782px;
        background-position: right;
        background-repeat: no-repeat;
        &__mobileImg{
            display: none;
        }
        &__buttons {
            margin-left: 135px;
            margin-top: 42px;
            margin-bottom: 102px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            a:hover{
                opacity:1
            }
            &__content{
                display: flex;
                flex-direction: row;
                align-items: center;
                //img{
                //    margin-top: 5px;
                //}

            }
        }
    }

    &__title {
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 900;
        font-size: 130px;
        line-height: 100.6%;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(170.33deg, #F36D0F 7.28%, #F8D48F 35.52%, #FFDFAD 63.19%, #FA9728 82.1%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 0.5px #FFFFFF;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        width: 639px;
        margin-top: 134px;
        margin-left: 135px;
    }


    &__table {

        //margin-top: 320px;
        width: 100%;
        margin-bottom: 112px;
        position: relative;

    }

    &__table-bg {
        grid-area: 1 / 1 / 2 / 2;
    }

    &__table-bg-orange {
        background: url('../../../../images/table/bg-table-orange.svg') no-repeat 100%;
        background-size: contain;
        position: absolute;
        right: 20px;
        z-index:0;
        width: 100%;
        height: 465px;

    }

    &__table-bg-black {
        position: relative;
        width: 100%;
        border-radius: 40px;

        margin-top: 30px;
        //padding:42px 57px
        padding-top: 42px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 10px;
        //overflow-x: auto;
        //overflow-y: hidden;
        //max-height: 1088px;
        //height: 100%;
        background: #000000;
    }

    &__title-image {
        background-image: url('../../../../images/pages/main/title-image.svg');
        width: 599px;
        height: 782px;
    }

    &__title-wrapper {
        display: flex;
        flex-direction: row;
    }

    &__cards {
        display: grid;
        position: relative;
        width: 100%;
        margin-top:-220px

    }

    &__cards-bg {
        grid-area: 1 / 1 / 2 / 2;
    }

    &__cards-bg-orange {
        background: url('../../../../images/pages/main/bg-header-orange.svg') no-repeat 100%;
        width: 100%;
        height: 463px;
        position: absolute;
        right: -10px;
        bottom:-20px
        //margin-left: 60px;
        //margin-top: 31px;
    }

    &__cards-bg-black {
        background: url('../../../../images/pages/main/bg-header-black.svg') no-repeat 100%;
        max-width: 1380px;
        width: 100%;
        background-size: cover;
        border-bottom-left-radius: 40px;
        border-top-left-radius: 40px;
        height: 463px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 123px;
        padding-right: 107px;
        padding-top: 52px;
        position: relative;
        overflow:hidden;
        &__floor{
            position: absolute;
            bottom:25px;
            z-index: 0;
            right: 60px;
            max-width: 1231px;
            width: 100%;
            //-webkit-filter: blur(2px);

        }
        &__cardBlock{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }
    }
    @media screen and (max-width: 1300px) {
        &__header{
            //height: max-content;
            &__buttons{
                margin-left: 60px;
            }
        }
        &__title{
            margin-left: 60px;
        }
        &__table-bg-orange{
            margin:0;
            right: 20px;
            width: 95%;
            background-size: contain;
        }
        &__cards-bg-orange{
            width: 95%;
            background-size: contain;
            bottom:-50px;
            right: -10px;
        }
        &__cards-bg-black {
            //margin-top:320px;
            height: max-content;
            background: #000000;
            border-radius: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            //justify-content: flex-start;
            gap: 30px;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;
            width: 100%;
            padding:40px;
            padding-bottom: 26px;
            &__floor{
                display: none;
            }
        }
    }
    @media screen and (max-width: 800px) {
        padding:0;
        gap: 36px;
        &__cards{
            margin-top: 0;
           padding:0 9px 0 9px
        }
        &__header{
            background: none ;
            background-size: cover;
            height: max-content;
            &__mobileImg{
                display: block;
                object-fit: cover;
                width: 100%;
                margin-top:-200px
            }
            &__buttons{
                margin-bottom: 0;
                margin-top: -30%;
                margin-left: 9px;
            }
        }
        &__title{
            display: none;
            margin-top: 486px;
            margin-left: 0;
        }
        &__cards-bg-orange,&__table-bg-orange{
            display: none;
        }
        &__cards-bg-black{
            display: flex;
            justify-content: center;
        }
        &__table{
            padding:0 9px 0 9px
        }
    }
    @media screen and (max-width: 500px) {
        &__header {


            &__mobileImg {
                display: block;
                object-fit: cover;
                width: 100%;
                margin-top: -90px
            }

        }
    }
    @media screen and (max-width: 400px) {
        &__header{

            &__buttons{
                flex-direction: column;
            }
        }
    }
}
