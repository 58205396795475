.SelectTokenFieldWrapper{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 140px;
  width: 100%;
  background:url("../../images/selectTokenField/selectTokenField.svg") no-repeat 100%;
  background-size: cover;
  padding: 8px 20px 8px 12px;
  cursor: pointer;
  transition: all 0.2s linear;

  &__active{
    background: rgba(255, 255, 255, 0.3);
    border-radius: 8px 8px 0 0;
    transition: all 0.2s linear;
  }
  &__SelectTokenField {
    border-radius: 8px;
    background:transparent;
    border:none !important;
    z-index: 2;
    width: 100%;
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 93.6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding:0;
    letter-spacing: -0.04em;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #FFFFFF;
    &__selectToken{
      width: 33px;
      height: 33px;
      img{
        margin:0 !important;
      }

    }
  }
  &__menu{
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    color: white;
    border: none;
    position: absolute;
    top: 110%;
    right: 0;
    z-index: 9999;
    width: 100%;
    display: none;
    transition: all 0.2s linear;
    padding:16px 12px;
    //height: 158px;
    &__content{
      overflow-y: auto;
      width: 100%;
      align-items: center;
      flex-direction: column;
      display: flex;
      gap: 8px;
      &__item{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 10px;
        font-family: 'Hind Vadodara';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 93.6%;
        letter-spacing: -0.04em;
        text-transform: uppercase;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #FFFFFF;
      }
      img{
        width: 33px;
        height: 33px;
      }
    }


  }

  &__menu__item:hover{
    background:#131313;

  }
  &__activeMenu{
    display: flex;
    opacity: 1;
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
  }
}
.SelectTokenFieldWrapper__menu__content::-webkit-scrollbar {
  width: 3px;

}

/* Track */
.SelectTokenFieldWrapper__menu__content::-webkit-scrollbar-track {

  border-radius: 10px;
  background:#414040;
}

/* Handle */
.SelectTokenFieldWrapper__menu__content::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #D9D9D9;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

