//@font-face {
//  font-family: 'Hind Vadodara';
//  src: url('../../fonts/kabelctt-medium.ttf') format('truetype'),
//  url('../../fonts/kabelctt-medium.woff') format('woff');
//}
.actionButtonContainer{
  position: relative;
  display: flex;
  //padding: 15px 55px 15px 55px;
  //padding-bottom: 18px;
  align-items: center;
  z-index: 5;
  justify-content: center;
  cursor: pointer;
  min-width: 148px;
  font-family: 'Hind Vadodara';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  &__absoluteBlock {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    width: 100%;
    svg {
      width: 100%;
      //max-height: 50px;
      //height: 100%;
    }
  }
  &__buttonImg{
    object-fit: contain;
    width: 100%;
  }
  &__buttonSubmit{
    width: 100%;
    position: absolute;
    box-shadow: none !important;
  }
  &__backgroundLabel{
    position: absolute;
    top: 6px;
    right: -4px;
    width: 90%;
    object-fit:contain;
    z-index: -1;
    transition: ease-in-out all 0.2s ;

  }
  &__backgroundLabel:hover{
    top:3px
  }
  stop{
    transition: all 0.2s linear;
  }

  p {
    font-family: 'Hind Vadodara';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 93.6%;
    text-align: center;
    text-transform: uppercase;
    align-self: center;
    color: #FFFFFF;
    margin:15px;
    display: flex;
    align-items: center;
    z-index: 0;
  }

}
.backgroundLabel{
  position: absolute;
  top: -0.8px;
  right: -4px;
  width: 101%;
  object-fit:contain;
  z-index: -1;
  transition: ease-in-out all 0.2s ;

}
.actionButtonContainer.disable{
  cursor: auto;
  pointer-events: none;
}

.actionButtonContainer:hover{
  .backgroundLabel{
    top:6px
  }
}
.actionButtonContainer:hover path{
  box-shadow: 0px 4px 4px rgba(254, 166, 65, 0.4);
}
.actionButtonContainer:hover stop:first-child {
  stop-color: #FEA23C;
}

.actionButtonContainer:hover stop:last-child {
  stop-color: #FED583;
}
.Red:hover stop:first-child {
  stop-color: #E3022C !important;
}
.Red:hover stop:last-child {
  stop-color: #F2106A !important;
}

