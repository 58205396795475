.LoopContainer{
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  gap: 27px;
  padding: 97px 34px 72px 150px;
  @media screen and (max-width: 1024px){

    padding: 56px 34px 56px 34px;
  }
  @media screen and (max-width: 900px){
    flex-direction: column;
    padding: 140px 10px 56px 10px;
  }
}
