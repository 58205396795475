.DashboardTableMobileItem{
  width: 100%;
  background: #131313;
  border-radius: 35px;
  padding: 50px 25px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  flex-direction: column;
  //gap: 40px;
  @media screen and (max-width: 600px){
    display: flex
  }
  &__infoBlock{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    //gap: 30px;
    &__infoRow{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      font-family: 'Hind Vadodara';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 93.6%;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      color: #FFFFFF;
      &__header{
        opacity: 0.9;
        width: 100%;
      }
    }
    &__infoRow:first-child{
      align-items: center;
    }
    &__token{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      img{
        width: 47px;
        height: 47px;
      }
    }
    &__balance{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      width: 100%;
      //gap: 25px;
      &__value{
        font-family: 'Hind Vadodara';
        font-weight: 500;
        font-size: 24px;
        line-height: 26px;
        text-transform: capitalize;
        font-feature-settings: 'pnum' on, 'lnum' on;
        background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

    }
    &__balance> *+*{
      margin-top: 25px;
    }
    &__apy{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      font-size: 24px;
      //gap: 7px;
      align-items: center;
      &__percent{
        background: linear-gradient(160.8deg, #F5D153 50.03%, #DD8039 72.76%, #FFD65A 83.56%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-size: 20px;

      }
      &__apr{
        font-size: 18px;
        border: 1px solid #313131;
        backdrop-filter: blur(2px);
        border-radius: 31px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding:14px 16px;
        gap: 6px;
        width: max-content;
        &__circle{
          background: #FFD65A;
          width: 10px;
          height: 10px;
          border-radius: 50%;

        }
      }

    }
    &__collateral{
      display: flex;
      flex-direction: row;
      align-items: center;
      //gap: 16px;
    }
    &__apy> *+*{
      margin-top: 7px;
    }
    &__collateral> *+*{
      margin-left: 16px;
    }
  }
  &__infoBlock> *+*{
    margin-top: 30px;
  }
  &__buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    //gap: 8px;
    > *+*{
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 500px){
    &__infoBlock {
      &__collateral, &__apy, &__balance, &__token {
        width: 100%;
      }
      &__apy{
        align-items: flex-start;
      }
    }
  }
  @media screen and (max-width: 340px){
    &__infoBlock {
      &__token {
        img{
          width: 30px;
          hight: 30px;
        }
      }
      &__infoRow {
        font-size: 14px;

      }
      &__balance__value{
        font-size: 22px;
      }
    }
  }

}
.DashboardTableMobileItem> *+*{
  margin-top: 40px;
}
